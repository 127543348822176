export const dataGreenMfiEnergyWash = [
 {
 "Product type": "Energy loan",
 "Product name": "Solar Energy Credit",
 "Product description": "The Solar Energy Credit (CES): This credit is specifically aimed at members to allow them wherever they are on the national territory, to have lamps or other solar solutions of quality and at a lower cost. The MFI through this product facilitates access to solar techNology for its customers through the implementation of suitable financial products.",
 "Product typology": "Climate-specific asset financing",
 "FSP name": "ACFB (Association des Caisses de Financement à la Base)",
 "FSP type": "Microfinance Institution",
 "FSP type (short)": "MFI",
 "Partner type": "MFI",
 "Payments": "Yes",
 "Savings": "Yes",
 "Credit": "Yes",
 "Insurance": "No",
 "Non-financial": "Yes",
 "Bundled": null,
 "Climate lens": "Climate-supportive",
 "Climate risk addressed": null,
 "Climate risk addressed 2": null,
 "Climate risk time horizon": "Long-term",
 "Before": "Yes",
 "During": null,
 "After": null,
 "Target end users": "Targets low-income individuals in underserved communities",
 "Rural vs urban": "Both",
 "Gender lens": "Uintentional",
 "End user coverage": null,
 "Country focus": "Benin",
 "Latin America": "No",
 "Sub-Saharan Africa": "Yes",
 "South Asia": "No",
 "South East Asia": "No",
 "Pacific": "No",
 "Other": "No",
 "Physical": "Operates a main office and 17 agents in the country.",
 "Digital": "Offers mobile banking.",
 "Website": "https://www.acfb-benin.org/",
 "Website Product": "https://acfb-benin.org/energie-solaire",
 "Unnamed: 36": null,
 },
 {
 "Product type": "Energy loan",
 "Product name": "Green Power Product",
 "Product description": "KIMB offers green power product to satisfy customer's needs of green power such as solar system- batteries- inverters- pumps- heaters- among others to have a safe and sustainable power.",
 "Product typology": "Climate-specific asset financing",
 "FSP name": "Alkuraimi Bank",
 "FSP type": "Microfinance Institution",
 "FSP type (short)": "MFI",
 "Partner type": "MFI",
 "Payments": "No",
 "Savings": "Yes",
 "Credit": "Yes",
 "Insurance": "Yes",
 "Non-financial": null,
 "Bundled": null,
 "Climate lens": "Climate-supportive",
 "Climate risk addressed": null,
 "Climate risk addressed 2": null,
 "Climate risk time horizon": "Long-term",
 "Before": "Yes",
 "During": null,
 "After": null,
 "Target end users": "Low-income households",
 "Rural vs urban": "Both",
 "Gender lens": "Uintentional",
 "End user coverage": null,
 "Country focus": "United Arab Emirates",
 "Latin America": "No",
 "Sub-Saharan Africa": "No",
 "South Asia": "No",
 "South East Asia": "No",
 "Pacific": "No",
 "Other": "Yes",
 "Physical": "Operates an office with various ATMs in the country.",
 "Digital": "Offers a bank mobile App.",
 "Website": "https://www.kuraimibank.com/en",
 "Website Product": "https://www.kuraimibank.com/en/products/green_energy",
 "Unnamed: 36": null,
 },
 {
 "Product type": "Energy and WASH loan",
 "Product name": "SWASTH",
 "Product description": "Annapurna offers a solar light loan to dwellers in rural India that face frequent electricity cuts. Through the Safe Water and Sanitation to Households (SWASTH) provides financial assistance to individuals to provide them access to safe water and sanitation facilities at household level.",
 "Product typology": "Broad climate adaptation loan",
 "FSP name": "Annapurna",
 "FSP type": "Microfinance Institution",
 "FSP type (short)": "MFI",
 "Partner type": "MFI",
 "Payments": "No",
 "Savings": "No",
 "Credit": "Yes",
 "Insurance": "Yes",
 "Non-financial": "Yes",
 "Bundled": null,
 "Climate lens": "Climate-supportive",
 "Climate risk addressed": null,
 "Climate risk addressed 2": null,
 "Climate risk time horizon": "Long-term",
 "Before": "Yes",
 "During": null,
 "After": null,
 "Target end users": "Poor communities in rural and peri urban areas",
 "Rural vs urban": "Both",
 "Gender lens": "Uintentional",
 "End user coverage": null,
 "Country focus": "India",
 "Latin America": "No",
 "Sub-Saharan Africa": "No",
 "South Asia": "Yes",
 "South East Asia": "No",
 "Pacific": "No",
 "Other": "No",
 "Physical": "Operates from an office and offers a loan account for the members through which repayments can be made from other methods available and suitable for the user.",
 "Digital": "Loan repayment portal through which members can with various options such as debtors bank, mobile wallet, internet banking, debit card, etc.",
 "Website": "https://annapurnafinance.in/",
 "Website Product": "https://annapurnafinance.in/products-services/",
 "Unnamed: 36": null,
 },
 {
 "Product type": "Area Yield Index Insurance",
 "Product name": "Area Yield Index Insurance",
 "Product description": "The AYII product pays out claims to farmers when the average yield in their area falls below a set level, regardless of the actual yield on each client’s farm.",
 "Product typology": "Index insurance",
 "FSP name": "APA",
 "FSP type": "Insurance company",
 "FSP type (short)": "Insurance company",
 "Partner type": null,
 "Payments": "No",
 "Savings": "No",
 "Credit": "No",
 "Insurance": "Yes",
 "Non-financial": null,
 "Bundled": "No",
 "Climate lens": "Climate-responsive",
 "Climate risk addressed": "Protects farmers against the damage to the insured growing crops due to excessive rainfall, flood, frost, hail damage, excessive heat wave, windstorm, pestilence, disease and drought",
 "Climate risk addressed 2": "Multi-peril",
 "Climate risk time horizon": "Short-term",
 "Before": "No",
 "During": "No",
 "After": "Yes",
 "Target end users": "Farmers",
 "Rural vs urban": "Rural",
 "Gender lens": "Uintentional",
 "End user coverage": "Micro",
 "Country focus": "Kenya",
 "Latin America": "No",
 "Sub-Saharan Africa": "Yes",
 "South Asia": "No",
 "South East Asia": "No",
 "Pacific": "No",
 "Other": "No",
 "Physical": "Operates from an office, money is transferred through bank accounts.",
 "Digital": null,
 "Website": "https://www.apainsurance.org/product_detail_crop.php",
 "Website Product": "https://www.apainsurance.org/product_detail_crop.php",
 "Unnamed: 36": null,
 },
 {
 "Product type": "Energy loan",
 "Product name": "Renewable Energy Credit",
 "Product description": "Renewable energy credit used to purchase of a gas oven, solar rechargeable lamp, etc. The individual as to be a member and have regularly moved your account.",
 "Product typology": "Climate-specific asset financing",
 "FSP name": "AREGAK UCO",
 "FSP type": "Microfinance Institution",
 "FSP type (short)": "MFI",
 "Partner type": "MFI",
 "Payments": "No",
 "Savings": "No",
 "Credit": "Yes",
 "Insurance": "No",
 "Non-financial": null,
 "Bundled": null,
 "Climate lens": "Climate-supportive",
 "Climate risk addressed": null,
 "Climate risk addressed 2": null,
 "Climate risk time horizon": "Long-term",
 "Before": "Yes",
 "During": null,
 "After": null,
 "Target end users": "Micro and small entreprenuers",
 "Rural vs urban": "Both",
 "Gender lens": "Uintentional",
 "End user coverage": null,
 "Country focus": "Armenia",
 "Latin America": "No",
 "Sub-Saharan Africa": "No",
 "South Asia": "No",
 "South East Asia": "No",
 "Pacific": "No",
 "Other": "Yes",
 "Physical": "Operates 25 branches across Armenia.",
 "Digital": null,
 "Website": "https://www.aregak.am/",
 "Website Product": "https://aregak.am/en/credits/solar-credit",
 "Unnamed: 36": null,
 },
 {
 "Product type": "Energy loan",
 "Product name": "Solar Credit",
 "Product description": "Offers credits for Solar PV systems.",
 "Product typology": "Climate-specific asset financing",
 "FSP name": "AREGAK UCO AR",
 "FSP type": "Microfinance Institution",
 "FSP type (short)": "MFI",
 "Partner type": "MFI",
 "Payments": "No",
 "Savings": "No",
 "Credit": "Yes",
 "Insurance": "No",
 "Non-financial": null,
 "Bundled": null,
 "Climate lens": "Climate-supportive",
 "Climate risk addressed": null,
 "Climate risk addressed 2": null,
 "Climate risk time horizon": "Long-term",
 "Before": "Yes",
 "During": null,
 "After": null,
 "Target end users": "Low-income population",
 "Rural vs urban": "Both",
 "Gender lens": "Uintentional",
 "End user coverage": null,
 "Country focus": "Armenia",
 "Latin America": "No",
 "Sub-Saharan Africa": "No",
 "South Asia": "No",
 "South East Asia": "No",
 "Pacific": "No",
 "Other": "Yes",
 "Physical": "Operates an office with 22 branches.",
 "Digital": null,
 "Website": "https://www.aregak.am/",
 "Website Product": "https://aregak.am/en/credits/solar-credit",
 "Unnamed: 36": null,
 },
 {
 "Product type": "Energy loan",
 "Product name": "Residential Energy Efficiency Lending",
 "Product description": "ACF operates the Residential Energy Efficiency Lending microloans designed to provide homeowners in rural areas with the funds necessary for energy-efficient renovation and construction work.",
 "Product typology": "Climate-specific input credit",
 "FSP name": "Asian Credit Fund",
 "FSP type": "Microfinance Institution",
 "FSP type (short)": "MFI",
 "Partner type": "MFI",
 "Payments": "No",
 "Savings": "No",
 "Credit": "Yes",
 "Insurance": "No",
 "Non-financial": "Yes",
 "Bundled": null,
 "Climate lens": "Climate-supportive",
 "Climate risk addressed": null,
 "Climate risk addressed 2": null,
 "Climate risk time horizon": "Long-term",
 "Before": "Yes",
 "During": null,
 "After": null,
 "Target end users": "Women in underserved communities",
 "Rural vs urban": "Rural",
 "Gender lens": "Intentional",
 "End user coverage": null,
 "Country focus": "Kazakstan",
 "Latin America": "No",
 "Sub-Saharan Africa": "No",
 "South Asia": "No",
 "South East Asia": "No",
 "Pacific": "No",
 "Other": "Yes",
 "Physical": "Operates offices in 7 branches.",
 "Digital": null,
 "Website": "https://asiancreditfund.com/en/",
 "Website Product": "https://asiancreditfund.com/en/loan-products",
 "Unnamed: 36": null,
 },
 {
 "Product type": "Energy loan",
 "Product name": "Alternative Energy Loan",
 "Product description": "Alternative Energy Loan.",
 "Product typology": "Climate-specific asset financing",
 "FSP name": "AVIYAN",
 "FSP type": "Microfinance Institution",
 "FSP type (short)": "MFI",
 "Partner type": "MFI",
 "Payments": "No",
 "Savings": "Yes",
 "Credit": "Yes",
 "Insurance": "Yes",
 "Non-financial": null,
 "Bundled": null,
 "Climate lens": "Climate-supportive",
 "Climate risk addressed": null,
 "Climate risk addressed 2": null,
 "Climate risk time horizon": "Long-term",
 "Before": "Yes",
 "During": null,
 "After": null,
 "Target end users": "Poor individuals in underserved communities",
 "Rural vs urban": "Rural",
 "Gender lens": "Uintentional",
 "End user coverage": null,
 "Country focus": "Nepal",
 "Latin America": "No",
 "Sub-Saharan Africa": "No",
 "South Asia": "Yes",
 "South East Asia": "No",
 "Pacific": "No",
 "Other": "No",
 "Physical": "Operates  a central office and 4 branches in the country.",
 "Digital": "Offers SMS service and mobile banking.",
 "Website": "https://www.aviyanlaghubitta.com/",
 "Website Product": "https://www.aviyanlaghubitta.com/service/loan/",
 "Unnamed: 36": null,
 },
 {
 "Product type": "Energy loan",
 "Product name": "Green Loan Energy (CrediVerde Energía)",
 "Product description": "Exclusive line of credit for micro-entrepreneurs who need to finance the purchase of Eco-efficient Technologies, such as: - Efficient ovens and stoves. - Efficient refrigeration systems. - Lighting systems.",
 "Product typology": "Climate-specific input credit",
 "FSP name": "Bancamia",
 "FSP type": "Microfinance Institution",
 "FSP type (short)": "MFI",
 "Partner type": "MFI",
 "Payments": "Yes",
 "Savings": "Yes",
 "Credit": "Yes",
 "Insurance": "Yes",
 "Non-financial": "Yes",
 "Bundled": null,
 "Climate lens": "Climate-supportive",
 "Climate risk addressed": null,
 "Climate risk addressed 2": null,
 "Climate risk time horizon": "Long-term",
 "Before": "Yes",
 "During": null,
 "After": null,
 "Target end users": "Micro/small entrepreneurs in impoverished communities",
 "Rural vs urban": "Both",
 "Gender lens": "Uintentional",
 "End user coverage": null,
 "Country focus": "Colombia",
 "Latin America": "Yes",
 "Sub-Saharan Africa": "No",
 "South Asia": "No",
 "South East Asia": "No",
 "Pacific": "No",
 "Other": "No",
 "Physical": "Operates a central office in Colombia.",
 "Digital": "Has a virtual office.",
 "Website": "https://www.bancamia.com.co/",
 "Website Product": "https://www.bancamia.com.co/productos/detalle/crediverde-energia-2a6",
 "Unnamed: 36": null,
 },
 {
 "Product type": "Energy loan",
 "Product name": "Renewable Banfondesa",
 "Product description": "Runs a wide range of loans for businesses and individuals to improve their life. Banfondesa finances the necessary investments to reduce your energy consumption and access to environmentally friendly technology.",
 "Product typology": "Climate-specific input credit",
 "FSP name": "BANFONDESA",
 "FSP type": "Microfinance Institution",
 "FSP type (short)": "MFI",
 "Partner type": "MFI",
 "Payments": "Yes",
 "Savings": "Yes",
 "Credit": "Yes",
 "Insurance": "No",
 "Non-financial": null,
 "Bundled": null,
 "Climate lens": "Climate-supportive",
 "Climate risk addressed": null,
 "Climate risk addressed 2": null,
 "Climate risk time horizon": "Long-term",
 "Before": "Yes",
 "During": null,
 "After": null,
 "Target end users": "Micro entreprenuers and low income earners",
 "Rural vs urban": "Both",
 "Gender lens": "Uintentional",
 "End user coverage": null,
 "Country focus": "República Dominicana",
 "Latin America": "Yes",
 "Sub-Saharan Africa": "No",
 "South Asia": "No",
 "South East Asia": "No",
 "Pacific": "No",
 "Other": "No",
 "Physical": "Operates 58 branch offices.",
 "Digital": null,
 "Website": "https://banfondesa.com.do/",
 "Website Product": "https://banfondesa.com.do/productos/prestamos/banfondesa-reNovable/",
 "Unnamed: 36": null,
 },
 {
 "Product type": "Energy loan",
 "Product name": "Loan for Solar Energy",
 "Product description": "Loan for the installation of a commercial solar energy system.",
 "Product typology": "Climate-specific asset financing",
 "FSP name": "Bank Hapoalim",
 "FSP type": "Bank",
 "FSP type (short)": "Bank",
 "Partner type": "Bank",
 "Payments": "No",
 "Savings": "Yes",
 "Credit": "Yes",
 "Insurance": "No",
 "Non-financial": null,
 "Bundled": null,
 "Climate lens": "Climate-supportive",
 "Climate risk addressed": null,
 "Climate risk addressed 2": null,
 "Climate risk time horizon": "Long-term",
 "Before": "Yes",
 "During": null,
 "After": null,
 "Target end users": "Not specified",
 "Rural vs urban": "Urban",
 "Gender lens": "Uintentional",
 "End user coverage": null,
 "Country focus": "Israel",
 "Latin America": "No",
 "Sub-Saharan Africa": "No",
 "South Asia": "No",
 "South East Asia": "No",
 "Pacific": "No",
 "Other": "Yes",
 "Physical": "Operates offices with 227 branches.",
 "Digital": "Offers internet and mobile banking ",
 "Website": "https://www.bankhapoalim.co.il/he/branches/",
 "Website Product": "https://www.bankhapoalim.co.il/he/_campaigns/solar-systems",
 "Unnamed: 36": null,
 },
 {
 "Product type": "Energy loan",
 "Product name": "Sustainable Credits for Rural Development",
 "Product description": "Loan Products: Energy Efficiency and Renewable Energy Credit. Investment capital for energy efficiency projects. Investment capital for renewable energy projects",
 "Product typology": "Climate-specific input credit",
 "FSP name": "Banco de Fomento Agropecuario (BFA)",
 "FSP type": "Bank",
 "FSP type (short)": "Bank",
 "Partner type": "Bank",
 "Payments": "Yes",
 "Savings": "Yes",
 "Credit": "Yes",
 "Insurance": "Yes",
 "Non-financial": "Yes",
 "Bundled": null,
 "Climate lens": "Climate-supportive",
 "Climate risk addressed": null,
 "Climate risk addressed 2": null,
 "Climate risk time horizon": "Long-term",
 "Before": "Yes",
 "During": null,
 "After": null,
 "Target end users": "Individuals and micro/small entrepreneurs",
 "Rural vs urban": "Rural",
 "Gender lens": "Uintentional",
 "End user coverage": null,
 "Country focus": "El Salvador",
 "Latin America": "Yes",
 "Sub-Saharan Africa": "No",
 "South Asia": "No",
 "South East Asia": "No",
 "Pacific": "No",
 "Other": "No",
 "Physical": "Operates a central office and 32 branches.",
 "Digital": "Offers online banking. ",
 "Website": "https://www.bfa.gob.sv/BFA/",
 "Website Product": "https://www.bfa.gob.sv/BFA/credito-desarrollo-rural/",
 "Unnamed: 36": null,
 },
 {
 "Product type": "Water and Sanitation and Energy Loan",
 "Product name": "BIMAS WASH Products",
 "Product description": "Bimas WASH Products targets to meet all water and sanitation needs for all clients. Some of the products include the SATO products and Water tank loans. The MFI also offers social loan that supports individuals to own solar systems and clean cook stoves.",
 "Product typology": "Broad climate adaptation loan",
 "FSP name": "BIMAS",
 "FSP type": "Microfinance Institution",
 "FSP type (short)": "MFI",
 "Partner type": "MFI",
 "Payments": "Yes",
 "Savings": "Yes",
 "Credit": "Yes",
 "Insurance": "Yes",
 "Non-financial": "Yes",
 "Bundled": null,
 "Climate lens": "Climate-supportive",
 "Climate risk addressed": null,
 "Climate risk addressed 2": null,
 "Climate risk time horizon": "Long-term",
 "Before": "Yes",
 "During": null,
 "After": null,
 "Target end users": "Individuals and businesses from underserved communities",
 "Rural vs urban": "Rural",
 "Gender lens": "Uintentional",
 "End user coverage": null,
 "Country focus": "Kenya",
 "Latin America": "No",
 "Sub-Saharan Africa": "Yes",
 "South Asia": "No",
 "South East Asia": "No",
 "Pacific": "No",
 "Other": "No",
 "Physical": "Operates a central office and 39 branches.",
 "Digital": "Offers mobile banking.",
 "Website": "https://www.bimaskenya.com/",
 "Website Product": "https://www.bimaskenya.com/index.php/products-and-services/water-sanitation-hygiene",
 "Unnamed: 36": null,
 },
 {
 "Product type": "Energy loan",
 "Product name": "Renewable Energy Loans",
 "Product description": "CAB offers the renewable energy loans in collaboration with the Jordan Renewable Energy and Energy Efficiency Fund (JREEEF) to finance renewable energy and energy saving products. JREEEF will pay the loans interest fees, allowing the beneficiaries to take advantage of the loans at zero interest.",
 "Product typology": "Climate-specific asset financing",
 "FSP name": "Cairo AMMAN Bank",
 "FSP type": "Bank",
 "FSP type (short)": "Bank",
 "Partner type": "Bank",
 "Payments": "Yes",
 "Savings": "Yes",
 "Credit": "Yes",
 "Insurance": "No",
 "Non-financial": null,
 "Bundled": null,
 "Climate lens": "Climate-supportive",
 "Climate risk addressed": null,
 "Climate risk addressed 2": null,
 "Climate risk time horizon": "Long-term",
 "Before": "Yes",
 "During": null,
 "After": null,
 "Target end users": "Individuals (Domestic/Home sector) & SMEs and Tourism Projects",
 "Rural vs urban": "Urban",
 "Gender lens": "Uintentional",
 "End user coverage": null,
 "Country focus": "Jordan",
 "Latin America": "No",
 "Sub-Saharan Africa": "No",
 "South Asia": "No",
 "South East Asia": "No",
 "Pacific": "No",
 "Other": "Yes",
 "Physical": "Operates branches in Jordan, Palestine, and Bahrain.",
 "Digital": "Offers online banking.",
 "Website": "https://www.cab.jo/",
 "Website Product": "https://www.cab.jo/service-details/265",
 "Unnamed: 36": null,
 },
 {
 "Product type": "Green Loan",
 "Product name": "Green Micro Credit Initiative",
 "Product description": "The MFI offers green micro credit towards projects protecting the environment for sustainability. The MFI has conducted a pilot project planting trees conducting as well a capacity building program.",
 "Product typology": "Climate-specific input credit",
 "FSP name": "CASHPOR Micro Credit",
 "FSP type": "Microfinance Institution",
 "FSP type (short)": "MFI",
 "Partner type": "MFI",
 "Payments": "No",
 "Savings": "No",
 "Credit": "Yes",
 "Insurance": "No",
 "Non-financial": "Yes",
 "Bundled": null,
 "Climate lens": "Climate-supportive",
 "Climate risk addressed": null,
 "Climate risk addressed 2": null,
 "Climate risk time horizon": "Long-term",
 "Before": "Yes",
 "During": null,
 "After": null,
 "Target end users": "Underserved communities (backward) in five states in India",
 "Rural vs urban": "Rural",
 "Gender lens": "Uintentional",
 "End user coverage": null,
 "Country focus": "India",
 "Latin America": "No",
 "Sub-Saharan Africa": "No",
 "South Asia": "No",
 "South East Asia": "Yes",
 "Pacific": "No",
 "Other": "No",
 "Physical": "Operates offices in the five states (Utter Pradesh, Bihar, Jharkhand, Chattisgarh, Madya).",
 "Digital": null,
 "Website": "http://www.cashpor.in/Default.aspx",
 "Website Product": "http://www.cashpor.in/pages/GMC.aspx",
 "Unnamed: 36": null,
 },
 {
 "Product type": "Energy and WASH loan",
 "Product name": "Energy Loans, WASH Loans",
 "Product description": "Offers loans for energy and WASH. Energy User loan (EUL) is designed to provide loan to fulfill the financial need to buy the solar facilities and saving cook stove. Energy Group Loan (EGL) is aimed to those who need small financing to purchase small items of energy solutions like solar lanterns and efficient cook stoves. Energy User loan (ESL) is designed to provide loan to solar entrepreneur to distribute the solar facilities and saving cookstove to end user. WASH Group Loans (WGL) is aimed to group of partners who need financing to build water pump, water connection, water filter and renovate or build toilet. WASH User Loans (WUL) is aimed to individual partner who need financing to build water pump, water connection, water filter and renovate or build toilet.",
 "Product typology": "Broad climate adaptation loan",
 "FSP name": "Chamroeun Microfinance PLC",
 "FSP type": "Microfinance Institution",
 "FSP type (short)": "MFI",
 "Partner type": "MFI",
 "Payments": "No",
 "Savings": "No",
 "Credit": "Yes",
 "Insurance": "No",
 "Non-financial": "Yes (Not specified )",
 "Bundled": null,
 "Climate lens": "Climate-supportive",
 "Climate risk addressed": null,
 "Climate risk addressed 2": null,
 "Climate risk time horizon": "Long-term",
 "Before": "Yes",
 "During": null,
 "After": null,
 "Target end users": "Low income entrepreneurs",
 "Rural vs urban": "Both",
 "Gender lens": "Uintentional",
 "End user coverage": null,
 "Country focus": "Cambodia",
 "Latin America": "No",
 "Sub-Saharan Africa": "No",
 "South Asia": "No",
 "South East Asia": "Yes",
 "Pacific": "No",
 "Other": "No",
 "Physical": "Operates an office.",
 "Digital": null,
 "Website": "https://www.chamroeun.com/",
 "Website Product": "https://www.chamroeun.com/page/wash-group-user-loan",
 "Unnamed: 36": null,
 },
 {
 "Product type": "Energy loan",
 "Product name": "Solar Loan",
 "Product description": "Offers a wide range of lending services to women, individuals, and businesses.",
 "Product typology": "Climate-specific asset financing",
 "FSP name": "Commercial International Bank Egypt (CIBEG)",
 "FSP type": "Bank",
 "FSP type (short)": "Bank",
 "Partner type": "Bank",
 "Payments": "Yes",
 "Savings": "Yes",
 "Credit": "Yes",
 "Insurance": "No",
 "Non-financial": "Yes",
 "Bundled": null,
 "Climate lens": "Climate-supportive",
 "Climate risk addressed": null,
 "Climate risk addressed 2": null,
 "Climate risk time horizon": "Long-term",
 "Before": "Yes",
 "During": null,
 "After": null,
 "Target end users": "Individuals and businesses",
 "Rural vs urban": "Both",
 "Gender lens": "Intentional",
 "End user coverage": null,
 "Country focus": "Egypt",
 "Latin America": "No",
 "Sub-Saharan Africa": "Yes",
 "South Asia": "No",
 "South East Asia": "No",
 "Pacific": "No",
 "Other": "No",
 "Physical": "Operates main office and 200 branches.",
 "Digital": "Offers mobile and internet banking.",
 "Website": "https://www.cibeg.com/en",
 "Website Product": "https://www.cibeg.com/en/personal/loans/solar",
 "Unnamed: 36": null,
 },
 {
 "Product type": "Energy loan",
 "Product name": "SME RENEWABLE ENERGY FINANCING",
 "Product description": "SME RENEWABLE ENERGY FINANCING, which is applicable to SMEs with a CIMB property loan or financing and runs another service for all other SMEs.  The product finances the purchase and installment of solar photovoltaic systems.",
 "Product typology": "Climate-specific input credit",
 "FSP name": "CIMB",
 "FSP type": "Bank",
 "FSP type (short)": "Bank",
 "Partner type": "Bank",
 "Payments": "Yes",
 "Savings": "Yes",
 "Credit": "Yes",
 "Insurance": "Yes",
 "Non-financial": null,
 "Bundled": null,
 "Climate lens": "Climate-supportive",
 "Climate risk addressed": null,
 "Climate risk addressed 2": null,
 "Climate risk time horizon": "Long-term",
 "Before": "Yes",
 "During": null,
 "After": null,
 "Target end users": "SMEs",
 "Rural vs urban": "Both",
 "Gender lens": "Uintentional",
 "End user coverage": null,
 "Country focus": "Malaysia",
 "Latin America": "No",
 "Sub-Saharan Africa": "No",
 "South Asia": "No",
 "South East Asia": "Yes",
 "Pacific": "No",
 "Other": "No",
 "Physical": "Operates an office with various branches.",
 "Digital": "Offers online and mobile banking.",
 "Website": "https://www.cimb.com.my/en/business/home.html",
 "Website Product": "https://www.cimb.com.my/en/business/solutions-products/financing/sme-financing/sme-renewable-energy-financing.html",
 "Unnamed: 36": null,
 },
 {
 "Product type": "Energy loan",
 "Product name": "Green Product",
 "Product description": "Credit aimed at people who wish to implement productive alternatives that favor the protection and sanitation of the environment in their economic activity, as well as for people who wish to make adjustments that allow the maintenance of clean energy.",
 "Product typology": "Climate-specific asset financing",
 "FSP name": "Contactar",
 "FSP type": "Microfinance Institution",
 "FSP type (short)": "MFI",
 "Partner type": "MFI",
 "Payments": "No",
 "Savings": "No",
 "Credit": "Yes",
 "Insurance": "Yes",
 "Non-financial": "Yes",
 "Bundled": null,
 "Climate lens": "Climate-supportive",
 "Climate risk addressed": null,
 "Climate risk addressed 2": null,
 "Climate risk time horizon": "Long-term",
 "Before": "Yes",
 "During": null,
 "After": null,
 "Target end users": "Individuals and micro/small entrepreneurs in impoverished communities",
 "Rural vs urban": "Both",
 "Gender lens": "Uintentional",
 "End user coverage": null,
 "Country focus": "Colombia",
 "Latin America": "Yes",
 "Sub-Saharan Africa": "No",
 "South Asia": "No",
 "South East Asia": "No",
 "Pacific": "No",
 "Other": "No",
 "Physical": "Operates 80 offices.",
 "Digital": null,
 "Website": "https://www.contactarcolombia.org/",
 "Website Product": "https://www.contactarcolombia.org/lineas-de-credito/producto-verde/",
 "Unnamed: 36": null,
 },
 {
 "Product type": "Energy loan",
 "Product name": "Renewable Energy Credit",
 "Product description": "Loans for purchasing  gas oven, rechargeable solar lamp",
 "Product typology": "Climate-specific asset financing",
 "FSP name": "Coopérative Chrétienne d’Epargne et de Crédit (COCEC)",
 "FSP type": "Microfinance Institution",
 "FSP type (short)": "MFI",
 "Partner type": "MFI",
 "Payments": "No",
 "Savings": "Yes",
 "Credit": "Yes",
 "Insurance": "No",
 "Non-financial": "Yes",
 "Bundled": null,
 "Climate lens": "Climate-supportive",
 "Climate risk addressed": null,
 "Climate risk addressed 2": null,
 "Climate risk time horizon": "Long-term",
 "Before": "Yes",
 "During": null,
 "After": null,
 "Target end users": "Individuals and micro/small enterprises",
 "Rural vs urban": "Both",
 "Gender lens": "Uintentional",
 "End user coverage": null,
 "Country focus": "Togo",
 "Latin America": "No",
 "Sub-Saharan Africa": "Yes",
 "South Asia": "No",
 "South East Asia": "No",
 "Pacific": "No",
 "Other": "No",
 "Physical": "Operates a central office and 10 agents in the country.",
 "Digital": "Offers SMS, mobile and internet banking.",
 "Website": "https://www.cocectogo.org/",
 "Website Product": "https://www.cocectogo.org/nos-produits-credit",
 "Unnamed: 36": null,
 },
 {
 "Product type": "Energy loan",
 "Product name": "Green Product",
 "Product description": "Green Loan designed for individuals or micro-enterprises that wish to implement environmentally friendly systems and practices in their activities, such as: Solar Panels, Eco Stoves, Paper or Plastic Recycling, Ecotourism, Organic Crops, Community Development Projects, etc.",
 "Product typology": "Climate-specific asset financing",
 "FSP name": "Credisol",
 "FSP type": "Microfinance Institution",
 "FSP type (short)": "MFI",
 "Partner type": "MFI",
 "Payments": "No",
 "Savings": "Yes",
 "Credit": "Yes",
 "Insurance": "Yes",
 "Non-financial": null,
 "Bundled": null,
 "Climate lens": "Climate-supportive",
 "Climate risk addressed": null,
 "Climate risk addressed 2": null,
 "Climate risk time horizon": "Long-term",
 "Before": "Yes",
 "During": null,
 "After": null,
 "Target end users": "Individuals and micro/small entrepreneurs",
 "Rural vs urban": "Both",
 "Gender lens": "Uintentional",
 "End user coverage": null,
 "Country focus": "Honduras",
 "Latin America": "Yes",
 "Sub-Saharan Africa": "No",
 "South Asia": "No",
 "South East Asia": "No",
 "Pacific": "No",
 "Other": "No",
 "Physical": "Operates a central office and 25 agents.",
 "Digital": "Offers mobile banking with an App.",
 "Website": "https://credisol.hn/",
 "Website Product": "https://credisol.hn/verde/",
 "Unnamed: 36": null,
 },
 {
 "Product type": "Energy loan",
 "Product name": "Renewable Energy Loan for Businesses",
 "Product description": "Credit line to finance the construction of sustainable energy generation systems, an alternative that generates savings and is good for the planet. The company can finance up to 100% of the project, and imported equipment can be financed, with the obligation to prove 100% of the financed value through an invoice.",
 "Product typology": "Climate-specific asset financing",
 "FSP name": "Cresol Baser",
 "FSP type": "Microfinance Institution",
 "FSP type (short)": "MFI",
 "Partner type": "MFI",
 "Payments": "Yes",
 "Savings": "Yes",
 "Credit": "Yes",
 "Insurance": "Yes",
 "Non-financial": "Yes",
 "Bundled": null,
 "Climate lens": "Climate-supportive",
 "Climate risk addressed": null,
 "Climate risk addressed 2": null,
 "Climate risk time horizon": "Long-term",
 "Before": "Yes",
 "During": null,
 "After": null,
 "Target end users": "Individuals and micro/small scale entrepreneurs",
 "Rural vs urban": "Both",
 "Gender lens": "Uintentional",
 "End user coverage": null,
 "Country focus": "Brazil",
 "Latin America": "Yes",
 "Sub-Saharan Africa": "No",
 "South Asia": "No",
 "South East Asia": "No",
 "Pacific": "No",
 "Other": "No",
 "Physical": "Operates a central office and three branches.",
 "Digital": "Operates mobile and online banking.",
 "Website": "https://cresol.com.br/",
 "Website Product": "https://cresol.com.br/credito-para-empresas/",
 "Unnamed: 36": null,
 },
 {
 "Product type": "Energy loan",
 "Product name": "EcoGreen Loan",
 "Product description": "The Green Credit - Ecomicro is an agricultural credit for the purchase of materials and inputs for the implementation of concrete adaptation measures that enable small farmers to cope with the effects of climate change. In addition to financing, EcoMicro provides technical support for the implementation of adaptation measures. This loan is available to farmers in the municipalities of Batallas, Sorata and Palos. Batallas, Sorata and Palos Blancos.",
 "Product typology": "Broad climate adaptation loan",
 "FSP name": "Diaconía IFD",
 "FSP type": "Microfinance Institution",
 "FSP type (short)": "MFI",
 "Partner type": "MFI",
 "Payments": "Yes",
 "Savings": "Yes",
 "Credit": "Yes",
 "Insurance": "No",
 "Non-financial": "Yes",
 "Bundled": null,
 "Climate lens": "Climate-supportive",
 "Climate risk addressed": null,
 "Climate risk addressed 2": null,
 "Climate risk time horizon": "Long-term",
 "Before": "Yes",
 "During": null,
 "After": null,
 "Target end users": null,
 "Rural vs urban": null,
 "Gender lens": null,
 "End user coverage": null,
 "Country focus": "Bolivia",
 "Latin America": "Yes",
 "Sub-Saharan Africa": "No",
 "South Asia": "No",
 "South East Asia": "No",
 "Pacific": "No",
 "Other": "No",
 "Physical": null,
 "Digital": null,
 "Website": "https://www.diaconia.bo/",
 "Website Product": "https://www.diaconia.bo/creditos/credito-ecoverde.html",
 "Unnamed: 36": null,
 },
 {
 "Product type": "Energy loan",
 "Product name": "Green Mortgages",
 "Product description": "Doha Bank support sustainable development goals and promotes environmental consciousness among homeowners and contribute to the nation’s mission to fight against climate change. Green Home Loan offer rewards environmentally friendly homemakers and prospective homeowners with extremely attractive benefits as one of the ways we encourage greener choices. Green Home Loan provides 0% interest for 1 year and a whole lot of privileges for homes that are certified as energy-efficient and environment-friendly. Take advantage of this amazing offer to ease your financial burden and relax in your dream home while contributing towards greener future. Green Mortgages provide discounts on loan fees or interest rates for homes that are certified as energy-efficient and environment-friendly. One of the best tools for making a dream home more affordable while saving on the cost of power, heating and cooling is the energy-efficient and environment-friendly “Green” Mortgages.",
 "Product typology": "Climate-specific input credit",
 "FSP name": "Doha Bank",
 "FSP type": "Bank",
 "FSP type (short)": "Bank",
 "Partner type": "Bank",
 "Payments": "No",
 "Savings": "Yes",
 "Credit": "Yes",
 "Insurance": "Yes",
 "Non-financial": "Yes",
 "Bundled": null,
 "Climate lens": "Climate-supportive",
 "Climate risk addressed": null,
 "Climate risk addressed 2": null,
 "Climate risk time horizon": "Long-term",
 "Before": "Yes",
 "During": null,
 "After": null,
 "Target end users": "Not specified",
 "Rural vs urban": "Both",
 "Gender lens": "Uintentional",
 "End user coverage": null,
 "Country focus": "United Arab Emirates",
 "Latin America": "No",
 "Sub-Saharan Africa": "No",
 "South Asia": "No",
 "South East Asia": "No",
 "Pacific": "No",
 "Other": "Yes",
 "Physical": "Operates offices in various countries.",
 "Digital": "Offers online and mobile banking.",
 "Website": "https://www.qa.dohabank.com",
 "Website Product": "https://qa.dohabank.com/personal/loans/housing-loan/green-mortgages/",
 "Unnamed: 36": null,
 },
 {
 "Product type": "Energy and WASH loan",
 "Product name": "Green Energy Loan",
 "Product description": "Offers both clean energy (Jikos, solar products, environment friendly products) and WASH loans. Energy: This is a loan product to facilitate the purchase of solar products, energy saving Jikos and other environmental friendly products. WASH:  Maji Safi Loan: Available for Acquisition of water purifiers, water connections and fully fitted water tanks. Kisima Loan: Loan to Facilitate sinking of shallow wells pumps and supporting structures. Both for households and institutions. Boma Safi Loan: This is a loan designed to facilitate construction of latrines/toilets, bathrooms, septic tanks and acquisition of related equipment and materials.",
 "Product typology": "Broad climate adaptation loan",
 "FSP name": "ECLOF Kenya",
 "FSP type": "Microfinance Institution",
 "FSP type (short)": "MFI",
 "Partner type": "MFI",
 "Payments": "No",
 "Savings": "No",
 "Credit": "Yes",
 "Insurance": "Yes",
 "Non-financial": "Yes (Not specified)",
 "Bundled": null,
 "Climate lens": "Climate-supportive",
 "Climate risk addressed": null,
 "Climate risk addressed 2": null,
 "Climate risk time horizon": "Long-term",
 "Before": "Yes",
 "During": null,
 "After": null,
 "Target end users": "Vulnerable people in all communities from all sectors of the ecoNomy",
 "Rural vs urban": "Both",
 "Gender lens": "Uintentional",
 "End user coverage": null,
 "Country focus": "Kenya",
 "Latin America": "No",
 "Sub-Saharan Africa": "Yes",
 "South Asia": "No",
 "South East Asia": "No",
 "Pacific": "No",
 "Other": "No",
 "Physical": "Operates an office with over 40 branches allover Kenya",
 "Digital": null,
 "Website": "https://www.eclof-kenya.org/about-eclof-kenya/",
 "Website Product": "https://www.eclof-kenya.org/services/energy-loan/",
 "Unnamed: 36": null,
 },
 {
 "Product type": "Energy loan",
 "Product name": "I can green (Puedo Verde)",
 "Product description": "Runs a wide range of loans for businesses and individuals to improve their life. The green product aims to finance energy efficiency solutions.",
 "Product typology": "Climate-specific input credit",
 "FSP name": "EZA CAPITAL",
 "FSP type": "Microfinance Institution",
 "FSP type (short)": "MFI",
 "Partner type": "MFI",
 "Payments": "No",
 "Savings": "No",
 "Credit": "Yes",
 "Insurance": "No",
 "Non-financial": "Yes",
 "Bundled": null,
 "Climate lens": "Climate-supportive",
 "Climate risk addressed": null,
 "Climate risk addressed 2": null,
 "Climate risk time horizon": "Long-term",
 "Before": "Yes",
 "During": null,
 "After": null,
 "Target end users": "Individuals and micro/small entreprenuers",
 "Rural vs urban": "Urban",
 "Gender lens": "Uintentional",
 "End user coverage": null,
 "Country focus": "Nicaragua",
 "Latin America": "Yes",
 "Sub-Saharan Africa": "No",
 "South Asia": "No",
 "South East Asia": "No",
 "Pacific": "No",
 "Other": "No",
 "Physical": "Operates an office.",
 "Digital": null,
 "Website": "https://ezacapital.com/",
 "Website Product": "https://ezacapital.com/productos-y-servicios/#puedoverde",
 "Unnamed: 36": null,
 },
 {
 "Product type": "Energy and WASH loan",
 "Product name": "EcoCredit",
 "Product description": "Ecocrédito is a financial product designed to benefit the health and family economy of the target population, contributing to the improvement of the quality of life and standard of living of the clientele served, through the acquisition of wood-saving stoves, water purifiers and solar panels for basic lighting for rural homes.",
 "Product typology": "Broad climate adaptation loan",
 "FSP name": "FAFIDESS",
 "FSP type": "Microfinance Institution",
 "FSP type (short)": "MFI",
 "Partner type": "MFI",
 "Payments": "Yes",
 "Savings": "No",
 "Credit": "Yes",
 "Insurance": "No",
 "Non-financial": "Yes",
 "Bundled": null,
 "Climate lens": "Climate-supportive",
 "Climate risk addressed": null,
 "Climate risk addressed 2": null,
 "Climate risk time horizon": "Long-term",
 "Before": "Yes",
 "During": null,
 "After": null,
 "Target end users": "Supports the under privileged women in Guatemala",
 "Rural vs urban": "Both",
 "Gender lens": "Intentional",
 "End user coverage": null,
 "Country focus": "Guatemala",
 "Latin America": "Yes",
 "Sub-Saharan Africa": "No",
 "South Asia": "No",
 "South East Asia": "No",
 "Pacific": "No",
 "Other": "No",
 "Physical": "Operates an office with coverage in 168 municipalities in Guatemala.",
 "Digital": null,
 "Website": "http://www.fafidess.org/#",
 "Website Product": "http://www.fafidess.org/ecocredito/",
 "Unnamed: 36": null,
 },
 {
 "Product type": "Energy loan",
 "Product name": "Green Credit",
 "Product description": "Credit for financing solar panels and solar water heaters. The institution offers as well technical assistance.",
 "Product typology": "Climate-specific input credit",
 "FSP name": "FDL de Guatemala",
 "FSP type": "Microfinance Institution",
 "FSP type (short)": "MFI",
 "Partner type": "MFI",
 "Payments": "No",
 "Savings": "No",
 "Credit": "Yes",
 "Insurance": "No",
 "Non-financial": null,
 "Bundled": null,
 "Climate lens": "Climate-supportive",
 "Climate risk addressed": null,
 "Climate risk addressed 2": null,
 "Climate risk time horizon": "Long-term",
 "Before": "Yes",
 "During": null,
 "After": null,
 "Target end users": "Individuals and micro/small entrepreneurs",
 "Rural vs urban": "Both",
 "Gender lens": "Uintentional",
 "End user coverage": null,
 "Country focus": "Guatemala",
 "Latin America": "Yes",
 "Sub-Saharan Africa": "No",
 "South Asia": "No",
 "South East Asia": "No",
 "Pacific": "No",
 "Other": "No",
 "Physical": "Operates an office and 3 sub-branches.",
 "Digital": null,
 "Website": "https://ffdldeguatemala.org/",
 "Website Product": "https://ffdldeguatemala.org/WebTabs/creditoVerde.html",
 "Unnamed: 36": null,
 },
 {
 "Product type": "Energy loan",
 "Product name": "Finca Solar Loan",
 "Product description": "Clients can purchase or lease clean electricity systems or products for use at home or to improve their small businesses. The systems also improve health and safety by eliminating the use of kerosene or charcoal.",
 "Product typology": "Climate-specific asset financing",
 "FSP name": "FINCA",
 "FSP type": "Microfinance Institution",
 "FSP type (short)": "MFI",
 "Partner type": "MFI",
 "Payments": "No",
 "Savings": "Yes",
 "Credit": "Yes",
 "Insurance": "Yes",
 "Non-financial": null,
 "Bundled": null,
 "Climate lens": "Climate-supportive",
 "Climate risk addressed": null,
 "Climate risk addressed 2": null,
 "Climate risk time horizon": "Long-term",
 "Before": "Yes",
 "During": null,
 "After": null,
 "Target end users": "Individuals and micro/small entrepreneurs in impoverished communities",
 "Rural vs urban": "Rural",
 "Gender lens": "Uintentional",
 "End user coverage": null,
 "Country focus": "Uganda",
 "Latin America": "Yes",
 "Sub-Saharan Africa": "No",
 "South Asia": "No",
 "South East Asia": "No",
 "Pacific": "No",
 "Other": "No",
 "Physical": "Operates a central office.",
 "Digital": null,
 "Website": "https://finca.org/",
 "Website Product": "https://finca.ug/products/finca-solar-loan/",
 "Unnamed: 36": "https://brightlifeuganda.com/",
 },
 {
 "Product type": "Energy and WASH loan",
 "Product name": "Foster Green Loan (Fomenta Verde)",
 "Product description": "Credit financing for the acquisition of environmentally friendly products in Fomenta, such as: solar panels, biodigesters and water harvesting.",
 "Product typology": "Broad climate adaptation loan",
 "FSP name": "FOMENTA S.C. DE R.L. DE C.V.",
 "FSP type": "Microfinance Institution",
 "FSP type (short)": "MFI",
 "Partner type": "MFI",
 "Payments": "No",
 "Savings": "Yes",
 "Credit": "Yes",
 "Insurance": "No",
 "Non-financial": null,
 "Bundled": null,
 "Climate lens": "Climate-supportive",
 "Climate risk addressed": null,
 "Climate risk addressed 2": null,
 "Climate risk time horizon": "Long-term",
 "Before": "Yes",
 "During": null,
 "After": null,
 "Target end users": "Not specific and focuses on many sectors to improve the livelihood of their clients",
 "Rural vs urban": "Both",
 "Gender lens": "Uintentional",
 "End user coverage": null,
 "Country focus": "El Salvadar",
 "Latin America": "Yes",
 "Sub-Saharan Africa": "No",
 "South Asia": "No",
 "South East Asia": "No",
 "Pacific": "No",
 "Other": "No",
 "Physical": "Operates an office and one branch.",
 "Digital": null,
 "Website": "http://fomenta.com.sv/index.html",
 "Website Product": "http://fomenta.com.sv/creditos.html#verde",
 "Unnamed: 36": null,
 },
 {
 "Product type": "Energy loan",
 "Product name": "Renewable Energy Loan",
 "Product description": "Collateral based loan product designed for the development & installation renewable energy sources like as solar panels, biogas, etc.",
 "Product typology": "Climate-specific asset financing",
 "FSP name": "Ganapati",
 "FSP type": "Microfinance Institution",
 "FSP type (short)": "MFI",
 "Partner type": "MFI",
 "Payments": "No",
 "Savings": "Yes",
 "Credit": "Yes",
 "Insurance": "Yes",
 "Non-financial": null,
 "Bundled": null,
 "Climate lens": "Climate-supportive",
 "Climate risk addressed": null,
 "Climate risk addressed 2": null,
 "Climate risk time horizon": "Long-term",
 "Before": "Yes",
 "During": null,
 "After": null,
 "Target end users": "Poor individuals in underserved communities",
 "Rural vs urban": "Rural",
 "Gender lens": "Uintentional",
 "End user coverage": null,
 "Country focus": "Nepal",
 "Latin America": "No",
 "Sub-Saharan Africa": "No",
 "South Asia": "Yes",
 "South East Asia": "No",
 "Pacific": "No",
 "Other": "No",
 "Physical": "Operates 43 branches.",
 "Digital": null,
 "Website": "https://www.ganapatimicro.com.np/",
 "Website Product": "https://www.ganapatimicro.com.np/loan.html",
 "Unnamed: 36": null,
 },
 {
 "Product type": "Energy loan",
 "Product name": "Renewable Energy Loan",
 "Product description": "The MFI offers a renewable energy loan both to members and non-members at slightly differing interest rates.",
 "Product typology": "Climate-specific asset financing",
 "FSP name": "Grameen Bikas",
 "FSP type": "Microfinance Institution",
 "FSP type (short)": "MFI",
 "Partner type": "MFI",
 "Payments": "No",
 "Savings": "Yes",
 "Credit": "Yes",
 "Insurance": "No",
 "Non-financial": "Yes",
 "Bundled": null,
 "Climate lens": "Climate-supportive",
 "Climate risk addressed": null,
 "Climate risk addressed 2": null,
 "Climate risk time horizon": "Long-term",
 "Before": "Yes",
 "During": null,
 "After": null,
 "Target end users": "Underserved communities",
 "Rural vs urban": "Rural",
 "Gender lens": "Uintentional",
 "End user coverage": null,
 "Country focus": "Nepal",
 "Latin America": "No",
 "Sub-Saharan Africa": "No",
 "South Asia": "Yes",
 "South East Asia": "No",
 "Pacific": "No",
 "Other": "No",
 "Physical": "Operates in 189 branches across the country.",
 "Digital": null,
 "Website": "http://gblbs.com.np/",
 "Website Product": "http://gblbs.com.np/loan",
 "Unnamed: 36": null,
 },
 {
 "Product type": "Energy loan",
 "Product name": "Energy/Environmental Loan",
 "Product description": "IBL Bank Green Energy Loans help individuals or companies finance energy saving solutions. IBL Bank Green Non-Energy Loans assist individuals or companies to finance pollution abatement, solid waste and waste water treatment, recycling, ecotourism, organic agriculture, and landscaping projects. ",
 "Product typology": "Climate-specific asset financing",
 "FSP name": "IBL BANK",
 "FSP type": "Bank",
 "FSP type (short)": "Bank",
 "Partner type": "Bank",
 "Payments": "Yes",
 "Savings": "Yes",
 "Credit": "Yes",
 "Insurance": "No",
 "Non-financial": "Yes",
 "Bundled": null,
 "Climate lens": "Climate-supportive",
 "Climate risk addressed": null,
 "Climate risk addressed 2": null,
 "Climate risk time horizon": "Long-term",
 "Before": "Yes",
 "During": null,
 "After": null,
 "Target end users": "Not specified",
 "Rural vs urban": "Both",
 "Gender lens": "Uintentional",
 "End user coverage": null,
 "Country focus": "LebaNon",
 "Latin America": "No",
 "Sub-Saharan Africa": "No",
 "South Asia": "No",
 "South East Asia": "No",
 "Pacific": "No",
 "Other": "Yes",
 "Physical": "Operates more than 20 branches in Lebanon.",
 "Digital": "Offers online banking.",
 "Website": "https://www.ibl.com.lb/english/home",
 "Website Product": "https://www.ibl.com.lb/english/personal-banking/loans/green-loans/energy-loan",
 "Unnamed: 36": null,
 },
 {
 "Product type": "Energy loan",
 "Product name": "Alternate Energy and Fuels",
 "Product description": "VII Capital Funds brings several Business, Financial and Commercial Private Industries together through interconnecting programs. Non-conventional commercial loans for biomass, wind, solar, geothermal, hydroelectric; lending and financing energy for international and domestic projects. In today's world, energy is a demanding resource that is harder to find and more expensive to acquire.",
 "Product typology": "Climate-specific asset financing",
 "FSP name": "II Capital Funds",
 "FSP type": "Microfinance Institution",
 "FSP type (short)": "MFI",
 "Partner type": "MFI",
 "Payments": "No",
 "Savings": "No",
 "Credit": "Yes",
 "Insurance": "Yes",
 "Non-financial": "Yes",
 "Bundled": null,
 "Climate lens": "Climate-supportive",
 "Climate risk addressed": null,
 "Climate risk addressed 2": null,
 "Climate risk time horizon": "Long-term",
 "Before": "Yes",
 "During": null,
 "After": null,
 "Target end users": "Business, Financial and Commercial Private Industries",
 "Rural vs urban": "Both",
 "Gender lens": "Uintentional",
 "End user coverage": null,
 "Country focus": "Saudi Arabia",
 "Latin America": "No",
 "Sub-Saharan Africa": "No",
 "South Asia": "Yes",
 "South East Asia": "No",
 "Pacific": "No",
 "Other": "Yes",
 "Physical": "Operates offices in various countries from USA, China, etc.",
 "Digital": null,
 "Website": "https://viicapitalfunds.com/",
 "Website Product": "https://viicapitalfunds.com/extensions-overview/international-commercial-real-estate-loans/alternate-energy-and-fuel",
 "Unnamed: 36": null,
 },
 {
 "Product type": "Energy loan",
 "Product name": "Alternative Energy Loan",
 "Product description": "Alternative Energy Loan offered to the customers who have completed 2 years with the MFI and is used for solar power and bio-gas plant. This loan is given to those customer who have successfully completion of 2 years in General Loan and demand for manufacturing Bio-Gas plant and solar power.  Loan ceiling is up-to Rs. 40,000/- without Collateral or Rs. 60,000/- Collateral and limit define as per Nepal Rastrya Bank Directives. The duration of Loan will be maximum 3 years and interest rate will be 15 percent.",
 "Product typology": "Climate-specific asset financing",
 "FSP name": "Infinity LBSL",
 "FSP type": "Microfinance Institution",
 "FSP type (short)": "MFI",
 "Partner type": "MFI",
 "Payments": "No",
 "Savings": "Yes",
 "Credit": "Yes",
 "Insurance": "Yes",
 "Non-financial": null,
 "Bundled": null,
 "Climate lens": "Climate-supportive",
 "Climate risk addressed": null,
 "Climate risk addressed 2": null,
 "Climate risk time horizon": "Long-term",
 "Before": "Yes",
 "During": null,
 "After": null,
 "Target end users": "Poor individuals in underserved communities",
 "Rural vs urban": "Rural",
 "Gender lens": "Uintentional",
 "End user coverage": null,
 "Country focus": "Nepal",
 "Latin America": "No",
 "Sub-Saharan Africa": "No",
 "South Asia": "Yes",
 "South East Asia": "No",
 "Pacific": "No",
 "Other": "No",
 "Physical": "Operates 88 branches.",
 "Digital": "Offers mobile banking services.",
 "Website": "https://infinitylbsl.com.np/#",
 "Website Product": "https://infinitylbsl.com.np/loan/",
 "Unnamed: 36": null,
 },
 {
 "Product type": "Energy loan",
 "Product name": "Finance My Energy",
 "Product description": "The MFI provides financing towards alternative renewable energy units and solar heater units powered by alternative energy sources.",
 "Product typology": "Climate-specific asset financing",
 "FSP name": "Islamic model microfinance JORDAN",
 "FSP type": "Microfinance Institution",
 "FSP type (short)": "MFI",
 "Partner type": "MFI",
 "Payments": "No",
 "Savings": "No",
 "Credit": "Yes",
 "Insurance": "No",
 "Non-financial": null,
 "Bundled": null,
 "Climate lens": "Climate-supportive",
 "Climate risk addressed": null,
 "Climate risk addressed 2": null,
 "Climate risk time horizon": "Long-term",
 "Before": "Yes",
 "During": null,
 "After": null,
 "Target end users": "Not specified",
 "Rural vs urban": "Urban",
 "Gender lens": "Uintentional",
 "End user coverage": null,
 "Country focus": "Jordan",
 "Latin America": "No",
 "Sub-Saharan Africa": "No",
 "South Asia": "No",
 "South East Asia": "No",
 "Pacific": "No",
 "Other": "Yes",
 "Physical": "Operates 6 branches in different parts in Jordan.",
 "Digital": null,
 "Website": "https://www.mifm.org.jo/",
 "Website Product": "https://www.mifm.org.jo/%D9%85%D9%86%D8%AA%D8%AC%D8%A7%D8%AA%D9%86%D8%A7",
 "Unnamed: 36": null,
 },
 {
 "Product type": "Energy and WASH loan",
 "Product name": "Solar Loan, WASH loan",
 "Product description": "JMH provides solar loans addressing the microenterprise limitations brought about by the power situation and climatic conditions. JMH offers  a WASH loan for water and sanitation issues and finance to address limitations due to power interruptions and climatic conditions for micro enterprises.",
 "Product typology": "Climate-specific asset financing",
 "FSP name": "J.M Honrado Foundation, Inc",
 "FSP type": "Microfinance Institution",
 "FSP type (short)": "MFI",
 "Partner type": "MFI",
 "Payments": "No",
 "Savings": "No",
 "Credit": "Yes",
 "Insurance": "Yes",
 "Non-financial": "Yes",
 "Bundled": null,
 "Climate lens": "Climate-supportive",
 "Climate risk addressed": null,
 "Climate risk addressed 2": null,
 "Climate risk time horizon": "Long-term",
 "Before": "Yes",
 "During": null,
 "After": null,
 "Target end users": "Micro entrepreneurs ",
 "Rural vs urban": "rural",
 "Gender lens": "Uintentional",
 "End user coverage": null,
 "Country focus": "Philippines",
 "Latin America": "No",
 "Sub-Saharan Africa": "No",
 "South Asia": "No",
 "South East Asia": "Yes",
 "Pacific": "No",
 "Other": "No",
 "Physical": "Operates an office in the region of operation with 38 branches.",
 "Digital": null,
 "Website": "https://jmhfi.com/",
 "Website Product": "https://jmhfi.com/financial-products/",
 "Unnamed: 36": null,
 },
 {
 "Product type": "Greenhouse Loan",
 "Product name": "Greenhouse Loan",
 "Product description": "The MFIs offers loans for organic agriculture and for greenhouse acquisition.",
 "Product typology": "Climate-specific input credit",
 "FSP name": "Jaime V Ongpin Microfinance Foundation INC",
 "FSP type": "Microfinance Institution",
 "FSP type (short)": "MFI",
 "Partner type": "MFI",
 "Payments": "No",
 "Savings": "Yes",
 "Credit": "Yes",
 "Insurance": "Yes",
 "Non-financial": "Yes",
 "Bundled": null,
 "Climate lens": "Climate-supportive",
 "Climate risk addressed": null,
 "Climate risk addressed 2": null,
 "Climate risk time horizon": "Long-term",
 "Before": "Yes",
 "During": null,
 "After": null,
 "Target end users": "Poor and low-income people in the cordillera administration region",
 "Rural vs urban": "Both",
 "Gender lens": "Uintentional",
 "End user coverage": null,
 "Country focus": "Philippines",
 "Latin America": "No",
 "Sub-Saharan Africa": "No",
 "South Asia": "No",
 "South East Asia": "Yes",
 "Pacific": "No",
 "Other": "No",
 "Physical": "Operates an office in the Cordillera Administrative region.",
 "Digital": null,
 "Website": "https://microfinance.jvofi.org/",
 "Website Product": "https://microfinance.jvofi.org/services/",
 "Unnamed: 36": null,
 },
 {
 "Product type": "Energy loan",
 "Product name": "Asset Finance Product",
 "Product description": "Asset financing to SACCO members to acquire solar panels and other assets etc.",
 "Product typology": "Climate-specific asset financing",
 "FSP name": "Jamil Sacco",
 "FSP type": "Microfinance Institution",
 "FSP type (short)": "MFI",
 "Partner type": "MFI",
 "Payments": "No",
 "Savings": "Yes",
 "Credit": "Yes",
 "Insurance": "Yes",
 "Non-financial": null,
 "Bundled": "Yes",
 "Climate lens": "Climate-supportive",
 "Climate risk addressed": null,
 "Climate risk addressed 2": null,
 "Climate risk time horizon": "Long-term",
 "Before": "Yes",
 "During": null,
 "After": null,
 "Target end users": "Individuals (SACCO members)",
 "Rural vs urban": "Both",
 "Gender lens": "Uintentional",
 "End user coverage": null,
 "Country focus": "Kenya",
 "Latin America": "No",
 "Sub-Saharan Africa": "Yes",
 "South Asia": "No",
 "South East Asia": "No",
 "Pacific": "No",
 "Other": "No",
 "Physical": "Operates a central office.",
 "Digital": null,
 "Website": "https://www.jamiisacco.com/?fbclid=IwAR2zh28iDbMsti25pemKG_ytxhajXi2zHe9e5E8UiRXHbq8Gu45YaSTFo9A",
 "Website Product": "https://www.jamiisacco.com/index.php/asset-finance",
 "Unnamed: 36": null,
 },
 {
 "Product type": "Energy loan",
 "Product name": "Energy Loan",
 "Product description": "Offers an energy loan of up to 200,000.",
 "Product typology": "Climate-specific asset financing",
 "FSP name": "JEEVAN",
 "FSP type": "Microfinance Institution",
 "FSP type (short)": "MFI",
 "Partner type": "MFI",
 "Payments": "Yes",
 "Savings": "Yes",
 "Credit": "Yes",
 "Insurance": "Yes",
 "Non-financial": "Yes",
 "Bundled": null,
 "Climate lens": "Climate-supportive",
 "Climate risk addressed": null,
 "Climate risk addressed 2": null,
 "Climate risk time horizon": "Long-term",
 "Before": "Yes",
 "During": null,
 "After": null,
 "Target end users": "Poor individuals in underserved communities",
 "Rural vs urban": "Rural",
 "Gender lens": "Uintentional",
 "End user coverage": null,
 "Country focus": "Nepal",
 "Latin America": "No",
 "Sub-Saharan Africa": "No",
 "South Asia": "Yes",
 "South East Asia": "No",
 "Pacific": "No",
 "Other": "No",
 "Physical": "Operates a central office and 150 branches in the country.",
 "Digital": "Offers SMS service and mobile banking.",
 "Website": "http://jeevanbikasmf.com/",
 "Website Product": "https://jeevanbikasmf.com/en/loan/",
 "Unnamed: 36": null,
 },
 {
 "Product type": "Energy loan",
 "Product name": "Green Financing",
 "Product description": "Offers 'Green Financing' among 15 types of product offerings",
 "Product typology": "Broad climate adaptation loan",
 "FSP name": "Juc Bank",
 "FSP type": "Microfinance Institution",
 "FSP type (short)": "MFI",
 "Partner type": "MFI",
 "Payments": "No",
 "Savings": "Yes",
 "Credit": "Yes",
 "Insurance": "Yes",
 "Non-financial": null,
 "Bundled": null,
 "Climate lens": "Climate-supportive",
 "Climate risk addressed": null,
 "Climate risk addressed 2": null,
 "Climate risk time horizon": "Long-term",
 "Before": "Yes",
 "During": null,
 "After": null,
 "Target end users": "Poor individuals in underserved communities",
 "Rural vs urban": "Rural",
 "Gender lens": "Uintentional",
 "End user coverage": null,
 "Country focus": "Nepal",
 "Latin America": "No",
 "Sub-Saharan Africa": "No",
 "South Asia": "Yes",
 "South East Asia": "No",
 "Pacific": "No",
 "Other": "No",
 "Physical": "Operates 39 branches in Nepal.",
 "Digital": null,
 "Website": "https://www.jucbank.com.np/",
 "Website Product": "https://www.jucbank.com.np/pages/loan-scheme",
 "Unnamed: 36": null,
 },
 {
 "Product type": "Energy loan",
 "Product name": "Biashara loan.",
 "Product description": "SME loans that can be secured or Not with payment duration of up to 36 months and 60months Up to KES 250million. ",
 "Product typology": "Climate-specific asset financing",
 "FSP name": "Kenya Commercial Bank (KCB)",
 "FSP type": "Bank",
 "FSP type (short)": "Bank",
 "Partner type": "Bank",
 "Payments": "Yes",
 "Savings": "Yes",
 "Credit": "Yes",
 "Insurance": "Yes",
 "Non-financial": "Yes",
 "Bundled": null,
 "Climate lens": "Climate-supportive",
 "Climate risk addressed": null,
 "Climate risk addressed 2": null,
 "Climate risk time horizon": "Long-term",
 "Before": "Yes",
 "During": null,
 "After": null,
 "Target end users": "SME's",
 "Rural vs urban": "Urban",
 "Gender lens": "Uintentional",
 "End user coverage": null,
 "Country focus": "Kenya",
 "Latin America": "No",
 "Sub-Saharan Africa": "Yes",
 "South Asia": "No",
 "South East Asia": "No",
 "Pacific": "No",
 "Other": "No",
 "Physical": "Operates a central office and branches all over Kenya.",
 "Digital": "Offers mobile, SMS and internet banking and agent banking.",
 "Website": "https://ke.kcbgroup.com/",
 "Website Product": null,
 "Unnamed: 36": null,
 },
 {
 "Product type": "Energy and WASH loan",
 "Product name": "Clean and Renewable Energy Solutions",
 "Product description": "This loan enables clients to acquire water tanks affordably to enable them harvest and store clean water for domestic use. Details about the clean energy loan are Not provided on the website. Clean Cooking: To address this challenge, KWFT finances households to purchase smokeless jikos which consume very little firewood and are friendly to the environment. Solar Lights: KWFToffers solar lighting products which are affordable and easy to install. The solar unit powers a television set, lights for many hours and also charges mobile phones for households and neighbors at a fee.  Solar Water Heaters: KWFT provides access to acquisition of solar heating solutions for home and commercial use. Electricity connection: KWFT Stima Loan facilitates electricity connections to the client’s home. Gas and cylinder financing: KWFT provides financing for Liquified Petroleum Gas (LPG) cooking solution that has proven to have immense benefits on health, environment and is cost effective. WASH Business Loan:  KWFT offers working capital to WASH entrepreneurs to enable them increase business stock and expand their business operations. WASH Tank: KWFT finances families to acquire Water Purifiers that remove germs, bacteria and pollutants from water to purify drinking water. WASH Micro-Assets Financing: KWFT finances entrepreneurs to acquire Micro-Assets needed to create efficiency while running the WASH business to maximize profits.",
 "Product typology": "Broad climate adaptation loan",
 "FSP name": "Kenya Womens Finance Trust (KWFT)",
 "FSP type": "Microfinance Institution",
 "FSP type (short)": "MFI",
 "Partner type": "MFI",
 "Payments": "No",
 "Savings": "Yes",
 "Credit": "Yes",
 "Insurance": "Yes",
 "Non-financial": null,
 "Bundled": null,
 "Climate lens": "Climate-supportive",
 "Climate risk addressed": "The bank offers a livestock insurance against illnesses,epidemics and deaths due to floods,windstorms.",
 "Climate risk addressed 2": "Multi-peril",
 "Climate risk time horizon": "Long-term",
 "Before": "No",
 "During": "No",
 "After": "Yes",
 "Target end users": "Supports the underserved women in the society",
 "Rural vs urban": "Both",
 "Gender lens": "Intentional",
 "End user coverage": null,
 "Country focus": "Kenya",
 "Latin America": "No",
 "Sub-Saharan Africa": "Yes",
 "South Asia": "No",
 "South East Asia": "No",
 "Pacific": "No",
 "Other": "No",
 "Physical": "Operates an office with over 229 branches allover Kenya.",
 "Digital": "Offers mobile banking as an option to the users.",
 "Website": "https://kwftbank.com/",
 "Website Product": "https://www.kwftbank.com/clean-and-renewable-energy-solutions/",
 "Unnamed: 36": null,
 },
 {
 "Product type": "Energy loan",
 "Product name": "Green Business Loan",
 "Product description": "GREEN BUSINESS LOANS to improve energy efficiency and other eco products.",
 "Product typology": "Climate-specific input credit",
 "FSP name": "Khan Bank",
 "FSP type": "Bank",
 "FSP type (short)": "Bank",
 "Partner type": "Bank",
 "Payments": "Yes",
 "Savings": "Yes",
 "Credit": "Yes",
 "Insurance": "Yes",
 "Non-financial": "Yes",
 "Bundled": null,
 "Climate lens": "Climate-supportive",
 "Climate risk addressed": null,
 "Climate risk addressed 2": null,
 "Climate risk time horizon": "Long-term",
 "Before": "Yes",
 "During": null,
 "After": null,
 "Target end users": "Individuals",
 "Rural vs urban": "Both",
 "Gender lens": "Uintentional",
 "End user coverage": null,
 "Country focus": "Mongolia",
 "Latin America": "No",
 "Sub-Saharan Africa": "No",
 "South Asia": "No",
 "South East Asia": "No",
 "Pacific": "No",
 "Other": "Yes",
 "Physical": "Operates an office and branches in Mongolia.",
 "Digital": "Offers online and mobile banking, mobile wallet.",
 "Website": "https://www.khanbank.com/mn/personal",
 "Website Product": "https://www.khanbank.com/en/corporate/product/551",
 "Unnamed: 36": null,
 },
 {
 "Product type": "Environmental Awareness Raising",
 "Product name": "Environmental Care Program",
 "Product description": "In line with one of the organization’s core values -Integrity of Creation, this program aims to empower, not just its staff, but also the partner-clients, to answer God’s call into taking care of His creations through various activities such as cleanliness drive, tree planting, urban gardening, etc. Non-financial service offered together with loans: Agriculture loan, Light Loan Program, Multi-Purpose Loan.",
 "Product typology": "Broad climate adaptation loan",
 "FSP name": "Light Microfinance",
 "FSP type": "Microfinance Institution",
 "FSP type (short)": "MFI",
 "Partner type": "MFI",
 "Payments": "No",
 "Savings": "No",
 "Credit": "Yes",
 "Insurance": "Yes",
 "Non-financial": "Yes",
 "Bundled": null,
 "Climate lens": "Climate-supportive",
 "Climate risk addressed": null,
 "Climate risk addressed 2": null,
 "Climate risk time horizon": "Long-term",
 "Before": "Yes",
 "During": null,
 "After": null,
 "Target end users": "Underserved communities",
 "Rural vs urban": "Both",
 "Gender lens": "Uintentional",
 "End user coverage": null,
 "Country focus": "Philipines",
 "Latin America": "No",
 "Sub-Saharan Africa": "No",
 "South Asia": "No",
 "South East Asia": "Yes",
 "Pacific": "No",
 "Other": "No",
 "Physical": "Operates an office.",
 "Digital": null,
 "Website": "https://light.org.ph/",
 "Website Product": "https://light.org.ph/",
 "Unnamed: 36": null,
 },
 {
 "Product type": "Energy loan",
 "Product name": "Energy Loan",
 "Product description": "Energy project loan (biogas loan, solar systems, cookstoves).",
 "Product typology": "Climate-specific asset financing",
 "FSP name": "MEKLIT MFI",
 "FSP type": "Microfinance Institution",
 "FSP type (short)": "MFI",
 "Partner type": "MFI",
 "Payments": "No",
 "Savings": "Yes",
 "Credit": "Yes",
 "Insurance": "No",
 "Non-financial": "Yes",
 "Bundled": null,
 "Climate lens": "Climate-supportive",
 "Climate risk addressed": null,
 "Climate risk addressed 2": null,
 "Climate risk time horizon": "Long-term",
 "Before": "Yes",
 "During": null,
 "After": null,
 "Target end users": "Low-income families",
 "Rural vs urban": "Rural",
 "Gender lens": "Uintentional",
 "End user coverage": null,
 "Country focus": "Ethiopia",
 "Latin America": "No",
 "Sub-Saharan Africa": "Yes",
 "South Asia": "No",
 "South East Asia": "No",
 "Pacific": "No",
 "Other": "No",
 "Physical": "Operates an office.",
 "Digital": null,
 "Website": "https://www.meklitmfi.com/",
 "Website Product": "https://www.meklitmfi.com/index.php/services",
 "Unnamed: 36": null,
 },
 {
 "Product type": "Energy and WASH loan",
 "Product name": "Sanitation Loans, Solar Product Loans",
 "Product description": "Micrédito provides environmentally friendly energy loans for rural clients living without electricity and finance towards the construction and improvement of sanitation services in communities in Nicaragua.",
 "Product typology": "Climate-specific asset financing",
 "FSP name": "MiCredito",
 "FSP type": "Microfinance Institution",
 "FSP type (short)": "MFI",
 "Partner type": "MFI",
 "Payments": "No",
 "Savings": "Yes",
 "Credit": "Yes",
 "Insurance": "Yes",
 "Non-financial": "Yes",
 "Bundled": null,
 "Climate lens": "Climate-supportive",
 "Climate risk addressed": null,
 "Climate risk addressed 2": null,
 "Climate risk time horizon": "Long-term",
 "Before": "Yes",
 "During": null,
 "After": null,
 "Target end users": "Supports the underserved in Nicaragua",
 "Rural vs urban": "Both",
 "Gender lens": "Uintentional",
 "End user coverage": null,
 "Country focus": "Nicuaragua",
 "Latin America": "Yes",
 "Sub-Saharan Africa": "No",
 "South Asia": "No",
 "South East Asia": "No",
 "Pacific": "No",
 "Other": "No",
 "Physical": "Operates an office with 13 branches in western Nicaragua.",
 "Digital": null,
 "Website": "https://www.micredito-en.com/about-micredito",
 "Website Product": "https://www.micredito-en.com/solar",
 "Unnamed: 36": null,
 },
 {
 "Product type": "Energy loan",
 "Product name": "Green Energy Loan",
 "Product description": "The Green Energy Loan allows homeowners and entrepreneurs operating across all sectors to finance and install renewable energy systems.",
 "Product typology": "Climate-specific asset financing",
 "FSP name": "MICROFUND",
 "FSP type": "Microfinance Institution",
 "FSP type (short)": "MFI",
 "Partner type": "MFI",
 "Payments": "Yes",
 "Savings": "Yes",
 "Credit": "Yes",
 "Insurance": "Yes",
 "Non-financial": "Yes",
 "Bundled": null,
 "Climate lens": "Climate-supportive",
 "Climate risk addressed": null,
 "Climate risk addressed 2": null,
 "Climate risk time horizon": "Long-term",
 "Before": "Yes",
 "During": null,
 "After": null,
 "Target end users": "Women and underserved communities",
 "Rural vs urban": "Both",
 "Gender lens": "Intentional",
 "End user coverage": null,
 "Country focus": "Jordan",
 "Latin America": "No",
 "Sub-Saharan Africa": "No",
 "South Asia": "No",
 "South East Asia": "No",
 "Pacific": "No",
 "Other": "Yes",
 "Physical": "Operates offices in the 12 of the regions in Jordan.",
 "Digital": null,
 "Website": "https://www.microfund.org.jo/en/who-we-are/",
 "Website Product": "https://www.microfund.org.jo/en/service_type/credit-financial-services/",
 "Unnamed: 36": null,
 },
 {
 "Product type": "Energy loan",
 "Product name": "Plantas Eléctricas",
 "Product description": "Micro loans are provided to individuals with or without an account at the bank. Agro loans are provided to farmers at any stage of the value chain. Motor Crédito finances electric plants for businesses or households.",
 "Product typology": "Climate-specific asset financing",
 "FSP name": "MOTOR CREDITO",
 "FSP type": "Microfinance Institution",
 "FSP type (short)": "MFI",
 "Partner type": "MFI",
 "Payments": "Yes",
 "Savings": "Yes",
 "Credit": "Yes",
 "Insurance": "Yes",
 "Non-financial": "Yes",
 "Bundled": null,
 "Climate lens": "Climate-supportive",
 "Climate risk addressed": null,
 "Climate risk addressed 2": null,
 "Climate risk time horizon": "Long-term",
 "Before": "Yes",
 "During": null,
 "After": null,
 "Target end users": "Individuals  from the private sector",
 "Rural vs urban": "Urban",
 "Gender lens": "Uintentional",
 "End user coverage": null,
 "Country focus": "República Dominicana",
 "Latin America": "Yes",
 "Sub-Saharan Africa": "No",
 "South Asia": "No",
 "South East Asia": "No",
 "Pacific": "No",
 "Other": "No",
 "Physical": "Operates offices in two countries.",
 "Digital": "Internet banking. ",
 "Website": "https://www.motorcredito.com.do/",
 "Website Product": "https://motorcredito.com.do/prestamos/financiamiento/plantas-electricas/",
 "Unnamed: 36": null,
 },
 {
 "Product type": "WASH and Energy",
 "Product name": "Asset Finance Loan",
 "Product description": "Financing of water tanks and clean energy systems.",
 "Product typology": "Climate-specific asset financing",
 "FSP name": "MUSONI DTM Ltd",
 "FSP type": "Microfinance Institution",
 "FSP type (short)": "MFI",
 "Partner type": "MFI",
 "Payments": "No",
 "Savings": "No",
 "Credit": "Yes",
 "Insurance": "No",
 "Non-financial": null,
 "Bundled": null,
 "Climate lens": "Climate-supportive",
 "Climate risk addressed": null,
 "Climate risk addressed 2": null,
 "Climate risk time horizon": "Long-term",
 "Before": "Yes",
 "During": null,
 "After": null,
 "Target end users": "Individuals ",
 "Rural vs urban": "Both",
 "Gender lens": "Uintentional",
 "End user coverage": null,
 "Country focus": "Kenya",
 "Latin America": "No",
 "Sub-Saharan Africa": "Yes",
 "South Asia": "No",
 "South East Asia": "No",
 "Pacific": "No",
 "Other": "No",
 "Physical": "Operates a central office and 30 branches.",
 "Digital": null,
 "Website": "https://musoni.co.ke/",
 "Website Product": "https://musoni.co.ke/business_loans#business_loans",
 "Unnamed: 36": null,
 },
 {
 "Product type": "Energy loan",
 "Product name": "Renewable Loan",
 "Product description": "Renewable Loan served as extra credit towards those interested in renewable energy (small solar home systems, biogas cookstoves, improved kitchen cookstoves, etc.). Offer for existing clients only with experience with the MFI.",
 "Product typology": "Climate-specific asset financing",
 "FSP name": "Nayasarathi",
 "FSP type": "Microfinance Institution",
 "FSP type (short)": "MFI",
 "Partner type": "MFI",
 "Payments": "No",
 "Savings": "Yes",
 "Credit": "Yes",
 "Insurance": "Yes",
 "Non-financial": null,
 "Bundled": null,
 "Climate lens": "Climate-supportive",
 "Climate risk addressed": null,
 "Climate risk addressed 2": null,
 "Climate risk time horizon": "Long-term",
 "Before": "Yes",
 "During": null,
 "After": null,
 "Target end users": "Underserved communities",
 "Rural vs urban": "Rural",
 "Gender lens": "Uintentional",
 "End user coverage": null,
 "Country focus": "Nepal",
 "Latin America": "No",
 "Sub-Saharan Africa": "No",
 "South Asia": "Yes",
 "South East Asia": "No",
 "Pacific": "No",
 "Other": "No",
 "Physical": "Operates offices in more than 7 branches.",
 "Digital": null,
 "Website": "http://nayasarathi.com/",
 "Website Product": "http://nayasarathi.com/service/Loan/Renewable%20Loan",
 "Unnamed: 36": null,
 },
 {
 "Product type": "Energy loan",
 "Product name": "Alternate Energy Loan",
 "Product description": "Alternate Energy Loan.",
 "Product typology": "Climate-specific asset financing",
 "FSP name": "Nepal Agro ",
 "FSP type": "Microfinance Institution",
 "FSP type (short)": "MFI",
 "Partner type": "MFI",
 "Payments": "No",
 "Savings": "Yes",
 "Credit": "Yes",
 "Insurance": "Yes",
 "Non-financial": null,
 "Bundled": null,
 "Climate lens": "Climate-supportive",
 "Climate risk addressed": null,
 "Climate risk addressed 2": null,
 "Climate risk time horizon": "Long-term",
 "Before": "Yes",
 "During": null,
 "After": null,
 "Target end users": "Poor individuals in underserved communities",
 "Rural vs urban": "Rural",
 "Gender lens": "Uintentional",
 "End user coverage": null,
 "Country focus": "Nepal",
 "Latin America": "No",
 "Sub-Saharan Africa": "No",
 "South Asia": "Yes",
 "South East Asia": "No",
 "Pacific": "No",
 "Other": "No",
 "Physical": null,
 "Digital": null,
 "Website": "https://nepalagro.com.np/",
 "Website Product": "https://nepalagro.com.np/loan/",
 "Unnamed: 36": null,
 },
 {
 "Product type": "Energy loan",
 "Product name": "Energy Loan",
 "Product description": "This loan is used for manufacturing biogas, Solar plant As well as Inverter purees. NULBSL offers this loan to those clients who have livestock and have utilized General loan with good repayment performance.",
 "Product typology": "Climate-specific asset financing",
 "FSP name": "Nirdhan",
 "FSP type": "Microfinance Institution",
 "FSP type (short)": "MFI",
 "Partner type": "MFI",
 "Payments": "No",
 "Savings": "Yes",
 "Credit": "Yes",
 "Insurance": "Yes",
 "Non-financial": "Yes",
 "Bundled": null,
 "Climate lens": "Climate-supportive",
 "Climate risk addressed": null,
 "Climate risk addressed 2": null,
 "Climate risk time horizon": "Long-term",
 "Before": "Yes",
 "During": null,
 "After": null,
 "Target end users": "Low-income groups",
 "Rural vs urban": "Rural",
 "Gender lens": "Uintentional",
 "End user coverage": null,
 "Country focus": "Nepal",
 "Latin America": "No",
 "Sub-Saharan Africa": "No",
 "South Asia": "Yes",
 "South East Asia": "No",
 "Pacific": "No",
 "Other": "No",
 "Physical": "Operates 181 branch offices.",
 "Digital": null,
 "Website": "https://www.nirdhan.com.np/",
 "Website Product": "https://www.nirdhan.com.np/loans/",
 "Unnamed: 36": null,
 },
 {
 "Product type": "Energy loan",
 "Product name": "Renewable Energy Credit",
 "Product description": "Committed to the community and the constant search for community development through the financing of solar panels that allow the generation of clean energy.",
 "Product typology": "Climate-specific asset financing",
 "FSP name": "PILARH",
 "FSP type": "Microfinance Institution",
 "FSP type (short)": "MFI",
 "Partner type": "MFI",
 "Payments": "No",
 "Savings": "Yes",
 "Credit": "Yes",
 "Insurance": "Yes",
 "Non-financial": "Yes",
 "Bundled": null,
 "Climate lens": "Climate-supportive",
 "Climate risk addressed": null,
 "Climate risk addressed 2": null,
 "Climate risk time horizon": "Long-term",
 "Before": "Yes",
 "During": null,
 "After": null,
 "Target end users": "Small and medium  entreprenuers",
 "Rural vs urban": "Both",
 "Gender lens": "Uintentional",
 "End user coverage": null,
 "Country focus": "Honduras",
 "Latin America": "Yes",
 "Sub-Saharan Africa": "No",
 "South Asia": "No",
 "South East Asia": "No",
 "Pacific": "No",
 "Other": "No",
 "Physical": "Operates 16 agent offices.",
 "Digital": null,
 "Website": "https://www.pilarh-opdf.org/",
 "Website Product": "https://www.pilarh-opdf.org/energia-renovable/",
 "Unnamed: 36": null,
 },
 {
 "Product type": "WASH loan",
 "Product name": "WASH Loans",
 "Product description": "Designed to finance individual and non-individual entities for the purposes of having access to Water, Sanitation and Hygiene enhancement.",
 "Product typology": "Broad climate adaptation loan",
 "FSP name": "Postal Bank Uganda",
 "FSP type": "Bank",
 "FSP type (short)": "Bank",
 "Partner type": "Bank",
 "Payments": "Yes",
 "Savings": "Yes",
 "Credit": "Yes",
 "Insurance": "No",
 "Non-financial": null,
 "Bundled": null,
 "Climate lens": "Climate-supportive",
 "Climate risk addressed": null,
 "Climate risk addressed 2": null,
 "Climate risk time horizon": "Long-term",
 "Before": "Yes",
 "During": null,
 "After": null,
 "Target end users": "Individuals and micro/small enterprises",
 "Rural vs urban": "Both",
 "Gender lens": "Intentional",
 "End user coverage": null,
 "Country focus": "Uganda",
 "Latin America": "No",
 "Sub-Saharan Africa": "Yes",
 "South Asia": "No",
 "South East Asia": "No",
 "Pacific": "No",
 "Other": "No",
 "Physical": "Operates a central office and various branches around the country.",
 "Digital": null,
 "Website": "https://www.postbank.co.ug/",
 "Website Product": "https://postbank.co.ug/products/water-sanitation-hygiene-loans-wash-loans/",
 "Unnamed: 36": null,
 },
 {
 "Product type": "Energy  and WASH loan",
 "Product name": "Biogas Loan, Latrine Loan",
 "Product description": "The energy loan is given for construction of biogas digesters for lighting and cooking. The MFI also offers latrine loans. ",
 "Product typology": "Broad climate adaptation loan",
 "FSP name": "Prasac Microfinance Institution",
 "FSP type": "Microfinance Institution",
 "FSP type (short)": "MFI",
 "Partner type": "MFI",
 "Payments": "Yes",
 "Savings": "Yes",
 "Credit": "Yes",
 "Insurance": "No",
 "Non-financial": null,
 "Bundled": null,
 "Climate lens": "Climate-supportive",
 "Climate risk addressed": null,
 "Climate risk addressed 2": null,
 "Climate risk time horizon": "Long-term",
 "Before": "Yes",
 "During": null,
 "After": null,
 "Target end users": "Not specified",
 "Rural vs urban": "Both",
 "Gender lens": "Uintentional",
 "End user coverage": null,
 "Country focus": "Cambodia",
 "Latin America": "No",
 "Sub-Saharan Africa": "No",
 "South Asia": "No",
 "South East Asia": "Yes",
 "Pacific": "No",
 "Other": "No",
 "Physical": "Operates an office and money is transferred through bank accounts.",
 "Digital": "Offers both internet and mobile banking options for the customers.",
 "Website": "https://www.prasac.com.kh/en/",
 "Website Product": "https://www.prasac.com.kh/en/services/loans/bio-gas-loans",
 "Unnamed: 36": null,
 },
 {
 "Product type": "Energy loan",
 "Product name": "Solar Plants",
 "Product description": "Individual credits for purchasing solar plants",
 "Product typology": "Climate-specific asset financing",
 "FSP name": "Prisma",
 "FSP type": "Microfinance Institution",
 "FSP type (short)": "MFI",
 "Partner type": "MFI",
 "Payments": "No",
 "Savings": "Yes",
 "Credit": "Yes",
 "Insurance": "No",
 "Non-financial": null,
 "Bundled": null,
 "Climate lens": "Climate-supportive",
 "Climate risk addressed": null,
 "Climate risk addressed 2": null,
 "Climate risk time horizon": "Long-term",
 "Before": "Yes",
 "During": null,
 "After": null,
 "Target end users": "Individuals and micro/small entrepreneurs",
 "Rural vs urban": "Both",
 "Gender lens": "Uintentional",
 "End user coverage": null,
 "Country focus": "Honduras",
 "Latin America": "Yes",
 "Sub-Saharan Africa": "No",
 "South Asia": "No",
 "South East Asia": "No",
 "Pacific": "No",
 "Other": "No",
 "Physical": "Operates a central office and 9 branches.",
 "Digital": null,
 "Website": "https://www.prismahonduras.com",
 "Website Product": "http://prismahonduras.com/productos/",
 "Unnamed: 36": null,
 },
 {
 "Product type": "Energy loan",
 "Product name": "Green Energy Loan",
 "Product description": "The Green Energy loan product that helps rural clients to access affordable renewable energy products on credit. Suppliers include: Burn, Marathoner and Sunking",
 "Product typology": "Climate-specific asset financing",
 "FSP name": "RAFODE",
 "FSP type": "Microfinance Institution",
 "FSP type (short)": "MFI",
 "Partner type": "MFI",
 "Payments": "No",
 "Savings": "No",
 "Credit": "Yes",
 "Insurance": "No",
 "Non-financial": null,
 "Bundled": null,
 "Climate lens": "Climate-supportive",
 "Climate risk addressed": null,
 "Climate risk addressed 2": null,
 "Climate risk time horizon": "Long-term",
 "Before": "Yes",
 "During": null,
 "After": null,
 "Target end users": "Individuals from rural communities",
 "Rural vs urban": "Rural",
 "Gender lens": "Uintentional",
 "End user coverage": null,
 "Country focus": "Kenya",
 "Latin America": "No",
 "Sub-Saharan Africa": "Yes",
 "South Asia": "No",
 "South East Asia": "No",
 "Pacific": "No",
 "Other": "No",
 "Physical": "Operates a central office and branches in 11 counties.",
 "Digital": null,
 "Website": "https://www.rafode.co.ke/",
 "Website Product": "https://www.rafode.co.ke/our-products/",
 "Unnamed: 36": null,
 },
 {
 "Product type": "Energy and WASH loan",
 "Product name": "Solar Loan",
 "Product description": "The MFI offers both solar, biogas, and water harvesting loans. Solar Loan: This product helps members to use the modern solar energy to light up their houses and businesses since Hydro power is not reliable. RUSSO supports Government in the rural electrification of members who cannot access hydro electric power.",
 "Product typology": "Broad climate adaptation loan",
 "FSP name": "Rushere SACCO",
 "FSP type": "Microfinance Institution",
 "FSP type (short)": "MFI",
 "Partner type": "MFI",
 "Payments": "No",
 "Savings": "Yes",
 "Credit": "Yes",
 "Insurance": "No",
 "Non-financial": null,
 "Bundled": null,
 "Climate lens": "Climate-supportive",
 "Climate risk addressed": null,
 "Climate risk addressed 2": null,
 "Climate risk time horizon": "Long-term",
 "Before": "Yes",
 "During": null,
 "After": null,
 "Target end users": "Supports the poor communities in rural areas",
 "Rural vs urban": "Rural",
 "Gender lens": "Uintentional",
 "End user coverage": null,
 "Country focus": "Uganda",
 "Latin America": "No",
 "Sub-Saharan Africa": "Yes",
 "South Asia": "No",
 "South East Asia": "No",
 "Pacific": "No",
 "Other": "No",
 "Physical": "Operates an office with 6 branches in southwest Uganda.",
 "Digital": "Offers mobile banking solutions to customers.",
 "Website": "https://rusheresacco.ug/About/",
 "Website Product": "https://rusheresacco.ug/solar-loan/",
 "Unnamed: 36": null,
 },
 {
 "Product type": "Energy loan",
 "Product name": "Solar Loan",
 "Product description": "Solar loan availed to privately manage schools to educational institutions.",
 "Product typology": "Climate-specific asset financing",
 "FSP name": "Safco support foundation",
 "FSP type": "Non Banking Finance Institution",
 "FSP type (short)": "MFI",
 "Partner type": "MFI",
 "Payments": "No",
 "Savings": "No",
 "Credit": "Yes",
 "Insurance": "Yes",
 "Non-financial": "Yes",
 "Bundled": null,
 "Climate lens": "Climate-supportive",
 "Climate risk addressed": null,
 "Climate risk addressed 2": null,
 "Climate risk time horizon": "Long-term",
 "Before": "Yes",
 "During": null,
 "After": null,
 "Target end users": "Poor individuals in underserved communities",
 "Rural vs urban": "Rural",
 "Gender lens": "Uintentional",
 "End user coverage": null,
 "Country focus": "Pakistan",
 "Latin America": "No",
 "Sub-Saharan Africa": "No",
 "South Asia": "Yes",
 "South East Asia": "No",
 "Pacific": "No",
 "Other": "No",
 "Physical": "Operates a central office and more than 10 branches. ",
 "Digital": null,
 "Website": "http://www.safcosupport.org/home/",
 "Website Product": "http://www.safcosupport.org/home/solar.php",
 "Unnamed: 36": null,
 },
 {
 "Product type": "Energy and WASH loan",
 "Product name": "Satin Credit Care",
 "Product description": "Satin Credit Care strengthens its members with loans for prolonged light facilities in electricity deficient areas for household and business, and for safe water & sanitation facilities.",
 "Product typology": "Broad climate adaptation loan",
 "FSP name": "Satin Credit care",
 "FSP type": "Microfinance Institution",
 "FSP type (short)": "MFI",
 "Partner type": "MFI",
 "Payments": "No",
 "Savings": "No",
 "Credit": "Yes",
 "Insurance": "No",
 "Non-financial": null,
 "Bundled": null,
 "Climate lens": "Climate-supportive",
 "Climate risk addressed": null,
 "Climate risk addressed 2": null,
 "Climate risk time horizon": "Long-term",
 "Before": "Yes",
 "During": null,
 "After": null,
 "Target end users": "Under privileged members of the community",
 "Rural vs urban": "Both",
 "Gender lens": "Intentional",
 "End user coverage": null,
 "Country focus": "India",
 "Latin America": "No",
 "Sub-Saharan Africa": "No",
 "South Asia": "Yes",
 "South East Asia": "No",
 "Pacific": "No",
 "Other": "No",
 "Physical": "Operates from an office and offers a loan account for the members through which repayments can be made from other methods available and suitable for the user.",
 "Digital": "Online loan repayment and mobile App.",
 "Website": "https://satincreditcare.com/",
 "Website Product": "https://satincreditcare.com/product-portfolio/",
 "Unnamed: 36": null,
 },
 {
 "Product type": "Energy loan",
 "Product name": "Green Light for Light",
 "Product description": "The MFI offers the Green Light for Light loan to support in the acquisition of solar lamp products.",
 "Product typology": "Climate-specific asset financing",
 "FSP name": "Simbag Sa Pag Asenso",
 "FSP type": "Microfinance Institution",
 "FSP type (short)": "MFI",
 "Partner type": "MFI",
 "Payments": "No",
 "Savings": "Yes",
 "Credit": "Yes",
 "Insurance": "Yes",
 "Non-financial": null,
 "Bundled": null,
 "Climate lens": "Climate-supportive",
 "Climate risk addressed": null,
 "Climate risk addressed 2": null,
 "Climate risk time horizon": "Long-term",
 "Before": "Yes",
 "During": null,
 "After": null,
 "Target end users": "Empowering of needy families",
 "Rural vs urban": "Both",
 "Gender lens": "Uintentional",
 "End user coverage": null,
 "Country focus": "Philipines",
 "Latin America": "No",
 "Sub-Saharan Africa": "No",
 "South Asia": "No",
 "South East Asia": "Yes",
 "Pacific": "No",
 "Other": "No",
 "Physical": "Operates a head office with more than 30 parish outreaches.",
 "Digital": null,
 "Website": "https://sedp.ph/about-us/",
 "Website Product": "https://sedp.ph/products/",
 "Unnamed: 36": null,
 },
 {
 "Product type": "Energy loan",
 "Product name": "Renewable Energy Credit",
 "Product description": "The MFI offers an environmental and renewable energy loan with technical support from UNCDF. The loan supports the uptake of solar home systems, biogas, and improved cook stoves by farmers.",
 "Product typology": "Climate-specific asset financing",
 "FSP name": "Small farmer development microfinance financial institution",
 "FSP type": "Microfinance Institution",
 "FSP type (short)": "MFI",
 "Partner type": "MFI",
 "Payments": "No",
 "Savings": "No",
 "Credit": "Yes",
 "Insurance": "No",
 "Non-financial": "Yes",
 "Bundled": null,
 "Climate lens": "Climate-supportive",
 "Climate risk addressed": null,
 "Climate risk addressed 2": null,
 "Climate risk time horizon": "Long-term",
 "Before": "Yes",
 "During": null,
 "After": null,
 "Target end users": "Farmers and SME's",
 "Rural vs urban": "Both",
 "Gender lens": "Uintentional",
 "End user coverage": null,
 "Country focus": "Nepal",
 "Latin America": "No",
 "Sub-Saharan Africa": "No",
 "South Asia": "Yes",
 "South East Asia": "No",
 "Pacific": "No",
 "Other": "No",
 "Physical": "Operates an office in Kathmandu in Nepal.",
 "Digital": null,
 "Website": "https://www.skbbl.com.np/main/",
 "Website Product": "https://www.skbbl.com.np/main/sana-kisan/25",
 "Unnamed: 36": null,
 },
 {
 "Product type": "Energy loan",
 "Product name": null,
 "Product description": "Offers interest free loans/credit based on the Sharia.",
 "Product typology": "Climate-specific asset financing",
 "FSP name": "Somali Microfinance Share Company",
 "FSP type": "Microfinance Institution",
 "FSP type (short)": "MFI",
 "Partner type": "MFI",
 "Payments": "No",
 "Savings": "Yes",
 "Credit": "Yes",
 "Insurance": "No",
 "Non-financial": "Yes",
 "Bundled": null,
 "Climate lens": "Climate-supportive",
 "Climate risk addressed": null,
 "Climate risk addressed 2": null,
 "Climate risk time horizon": "Long-term",
 "Before": "Yes",
 "During": null,
 "After": null,
 "Target end users": "Entrepreneurs, low-income individuals ",
 "Rural vs urban": "Both",
 "Gender lens": "Uintentional",
 "End user coverage": null,
 "Country focus": "Ethiopia",
 "Latin America": "No",
 "Sub-Saharan Africa": "Yes",
 "South Asia": "No",
 "South East Asia": "No",
 "Pacific": "No",
 "Other": "No",
 "Physical": "Operates an office and 42 branches.",
 "Digital": "Offers electronic payments, mobile and agent banking.",
 "Website": "https://www.somalimfi.com/",
 "Website Product": "https://www.somalimfi.com/loans",
 "Unnamed: 36": null,
 },
 {
 "Product type": "Energy loan",
 "Product name": "Renewable Energy Loan",
 "Product description": "Offers a renewable energy loan from 9 to 12 months.",
 "Product typology": "Climate-specific asset financing",
 "FSP name": "Soon Valley Development ",
 "FSP type": "Microfinance Institution",
 "FSP type (short)": "MFI",
 "Partner type": "MFI",
 "Payments": "No",
 "Savings": "No",
 "Credit": "Yes",
 "Insurance": "No",
 "Non-financial": null,
 "Bundled": null,
 "Climate lens": "Climate-supportive",
 "Climate risk addressed": null,
 "Climate risk addressed 2": null,
 "Climate risk time horizon": "Long-term",
 "Before": "Yes",
 "During": null,
 "After": null,
 "Target end users": "Poor individuals in underserved communities",
 "Rural vs urban": "Rural",
 "Gender lens": "Uintentional",
 "End user coverage": null,
 "Country focus": "Pakistan",
 "Latin America": "No",
 "Sub-Saharan Africa": "No",
 "South Asia": "Yes",
 "South East Asia": "No",
 "Pacific": "No",
 "Other": "No",
 "Physical": "Operates a central office and 9 branches.",
 "Digital": null,
 "Website": "https://svdp.org.pk/",
 "Website Product": "https://svdp.org.pk/",
 "Unnamed: 36": null,
 },
 {
 "Product type": "Energy and WASH loan",
 "Product name": "WASH Loan, Clean Energy Loan",
 "Product description": "SPI offers both WASH and clean Energy loans.",
 "Product typology": "Broad climate adaptation loan",
 "FSP name": "Specialized Financial and Promotional Institution (SFPI)",
 "FSP type": "Microfinance Institution",
 "FSP type (short)": "MFI",
 "Partner type": "MFI",
 "Payments": "No",
 "Savings": "Yes",
 "Credit": "Yes",
 "Insurance": "Yes",
 "Non-financial": null,
 "Bundled": null,
 "Climate lens": "Climate-supportive",
 "Climate risk addressed": null,
 "Climate risk addressed 2": null,
 "Climate risk time horizon": "Long-term",
 "Before": "Yes",
 "During": null,
 "After": null,
 "Target end users": "Underprivileged persons of the communities such as women, people with disabilities ",
 "Rural vs urban": "Both",
 "Gender lens": "Intentional",
 "End user coverage": null,
 "Country focus": "Ethiopia",
 "Latin America": "No",
 "Sub-Saharan Africa": "Yes",
 "South Asia": "No",
 "South East Asia": "No",
 "Pacific": "No",
 "Other": "No",
 "Physical": "Operates an office with close to 28 branches and 12 subbranches.",
 "Digital": null,
 "Website": "https://sfpimfi.org/about-us/",
 "Website Product": "https://sfpimfi.org/service-product/",
 "Unnamed: 36": null,
 },
 {
 "Product type": "Energy loan",
 "Product name": "Alternative Energy Loan",
 "Product description": "Alternative Energy Loan to acquire solar home systems.",
 "Product typology": "Climate-specific asset financing",
 "FSP name": "Support Microfinance",
 "FSP type": "Microfinance Institution",
 "FSP type (short)": "MFI",
 "Partner type": "MFI",
 "Payments": "No",
 "Savings": "Yes",
 "Credit": "Yes",
 "Insurance": "Yes",
 "Non-financial": null,
 "Bundled": null,
 "Climate lens": "Climate-supportive",
 "Climate risk addressed": null,
 "Climate risk addressed 2": null,
 "Climate risk time horizon": "Long-term",
 "Before": "Yes",
 "During": null,
 "After": null,
 "Target end users": "Poor individuals in underserved communities",
 "Rural vs urban": "Both",
 "Gender lens": "Uintentional",
 "End user coverage": null,
 "Country focus": "Nepal",
 "Latin America": "No",
 "Sub-Saharan Africa": "No",
 "South Asia": "Yes",
 "South East Asia": "No",
 "Pacific": "No",
 "Other": "No",
 "Physical": "Operates 24 branches in Nepal.",
 "Digital": null,
 "Website": "http://www.supportmicrofinance.com.np/",
 "Website Product": "http://www.supportmicrofinance.com.np/credit",
 "Unnamed: 36": null,
 },
 {
 "Product type": "Energy loan",
 "Product name": "Renewable Energy Loan",
 "Product description": "Renewable energy loan.",
 "Product typology": "Climate-specific asset financing",
 "FSP name": "Swabalamban",
 "FSP type": "Microfinance Institution",
 "FSP type (short)": "MFI",
 "Partner type": "MFI",
 "Payments": "No",
 "Savings": "Yes",
 "Credit": "Yes",
 "Insurance": "Yes",
 "Non-financial": null,
 "Bundled": null,
 "Climate lens": "Climate-supportive",
 "Climate risk addressed": null,
 "Climate risk addressed 2": null,
 "Climate risk time horizon": "Long-term",
 "Before": "Yes",
 "During": null,
 "After": null,
 "Target end users": "Underserved communities",
 "Rural vs urban": "Rural",
 "Gender lens": "Uintentional",
 "End user coverage": null,
 "Country focus": "Nepal",
 "Latin America": "No",
 "Sub-Saharan Africa": "No",
 "South Asia": "Yes",
 "South East Asia": "No",
 "Pacific": "No",
 "Other": "No",
 "Physical": "Operates offices in the 7 provinces of the country.",
 "Digital": null,
 "Website": "http://swbbl.com.np/",
 "Website Product": "http://swbbl.com.np/loan-products/",
 "Unnamed: 36": null,
 },
 {
 "Product type": "Energy loan",
 "Product name": "Alternative Energy Loan",
 "Product description": "Alternative Energy Loan whose main purpose is to improve the health and reduce regular expenses of the client. SLBBL will provide loan to install bio-gas plant and solar energy up to 60,000.",
 "Product typology": "Climate-specific asset financing",
 "FSP name": "Swarojgar Laghubitta",
 "FSP type": "Microfinance Institution",
 "FSP type (short)": "MFI",
 "Partner type": "MFI",
 "Payments": "Yes",
 "Savings": "Yes",
 "Credit": "Yes",
 "Insurance": "Yes",
 "Non-financial": null,
 "Bundled": null,
 "Climate lens": "Climate-supportive",
 "Climate risk addressed": null,
 "Climate risk addressed 2": null,
 "Climate risk time horizon": "Long-term",
 "Before": "Yes",
 "During": null,
 "After": null,
 "Target end users": "Underserved communities",
 "Rural vs urban": "Rural",
 "Gender lens": "Intentional",
 "End user coverage": null,
 "Country focus": "Nepal",
 "Latin America": "No",
 "Sub-Saharan Africa": "No",
 "South Asia": "Yes",
 "South East Asia": "No",
 "Pacific": "No",
 "Other": "No",
 "Physical": "Operates offices with 7 branches.",
 "Digital": null,
 "Website": "https://www.slbbl.com.np/",
 "Website Product": "https://www.slbbl.com.np/products/alternative-emergu-loan/",
 "Unnamed: 36": null,
 },
 {
 "Product type": "Energy and WASH  loan",
 "Product name": "Alternative Energy",
 "Product description": "The MFI offers alternative energy loans to rural areas without electricity grid to own one of the following products: home-based solar system, running enterprise, extracting water for agriculture, safe water for drinking.",
 "Product typology": "Climate-specific asset financing",
 "FSP name": "Thardeep",
 "FSP type": "Microfinance Institution",
 "FSP type (short)": "MFI",
 "Partner type": "MFI",
 "Payments": "No",
 "Savings": "No",
 "Credit": "Yes",
 "Insurance": "Yes",
 "Non-financial": null,
 "Bundled": null,
 "Climate lens": "Climate-supportive",
 "Climate risk addressed": null,
 "Climate risk addressed 2": null,
 "Climate risk time horizon": "Long-term",
 "Before": "Yes",
 "During": null,
 "After": null,
 "Target end users": "Low-income individuals from underserved areas",
 "Rural vs urban": "Rural",
 "Gender lens": "Uintentional",
 "End user coverage": null,
 "Country focus": "Pakistan",
 "Latin America": "No",
 "Sub-Saharan Africa": "No",
 "South Asia": "Yes",
 "South East Asia": "No",
 "Pacific": "No",
 "Other": "No",
 "Physical": "Operates a main office and more than 80 branches in the regions across the country.",
 "Digital": null,
 "Website": "https://www.tmf.org.pk/",
 "Website Product": "https://www.tmf.org.pk/loan-products/",
 "Unnamed: 36": null,
 },
 {
 "Product type": "Energy loan",
 "Product name": "Solar Loan",
 "Product description": "Microfinance bank offers a green loan for energy saving appliances and clean energy solutions from renewable energy solutions. TFMFB offers the Solar Loan to improve access to clean and reliable electricity sources for households",
 "Product typology": "Climate-specific asset financing",
 "FSP name": "The First Microfinance Bank",
 "FSP type": "Microfinance Institution",
 "FSP type (short)": "MFI",
 "Partner type": "MFI",
 "Payments": "No",
 "Savings": "Yes",
 "Credit": "Yes",
 "Insurance": "No",
 "Non-financial": null,
 "Bundled": null,
 "Climate lens": "Climate-supportive",
 "Climate risk addressed": null,
 "Climate risk addressed 2": null,
 "Climate risk time horizon": "Long-term",
 "Before": "Yes",
 "During": null,
 "After": null,
 "Target end users": "Business, Financial and Commercial Private Industries",
 "Rural vs urban": "Both",
 "Gender lens": "Uintentional",
 "End user coverage": null,
 "Country focus": "Afghanistan",
 "Latin America": "No",
 "Sub-Saharan Africa": "No",
 "South Asia": "Yes",
 "South East Asia": "No",
 "Pacific": "No",
 "Other": "Yes",
 "Physical": "Operates offices with more than 40 branches in the country.",
 "Digital": "Offers online banking.",
 "Website": "https://fmfb.com.af/",
 "Website Product": "https://fmfb.com.af/products/microfinance-loan-products-details/",
 "Unnamed: 36": null,
 },
 {
 "Product type": "Energy loan",
 "Product name": "Eco Loan",
 "Product description": "The loan can be used for people who wish to purchase eco-friendly equipment that supports improved health or ecological benefits, such as an eco-electric heater or an eco-air purifier.",
 "Product typology": "Climate-specific asset financing",
 "FSP name": "Vision Fund",
 "FSP type": "Microfinance Institution",
 "FSP type (short)": "MFI",
 "Partner type": "MFI",
 "Payments": "No",
 "Savings": "Yes",
 "Credit": "Yes",
 "Insurance": "Yes",
 "Non-financial": null,
 "Bundled": null,
 "Climate lens": "Climate-supportive",
 "Climate risk addressed": null,
 "Climate risk addressed 2": null,
 "Climate risk time horizon": "Long-term",
 "Before": "Yes",
 "During": null,
 "After": null,
 "Target end users": "SME's",
 "Rural vs urban": "Both",
 "Gender lens": "Uintentional",
 "End user coverage": null,
 "Country focus": "Mongolia",
 "Latin America": "No",
 "Sub-Saharan Africa": "No",
 "South Asia": "No",
 "South East Asia": "No",
 "Pacific": "No",
 "Other": "Yes",
 "Physical": "Operates an office and partners with offices in close to 5 countries.",
 "Digital": null,
 "Website": "https://www.visionfund.org/",
 "Website Product": "https://www.visionfund.org/where-we-work/asia/mongolia/our-work",
 "Unnamed: 36": null,
 },
 {
 "Product type": "Energy loan",
 "Product name": "Green Loan",
 "Product description": "The product designed for energy and environment seeks to offer a variety of financial solutions that allow borrowers to buy energy saving home appliances from partner companies. Moreover, the product is offered to buy solar boilers and similar alternatives to generate clean energy from renewable sources.",
 "Product typology": "Climate-specific input credit",
 "FSP name": "WATANI LOAN",
 "FSP type": "Microfinance Institution",
 "FSP type (short)": "MFI",
 "Partner type": "MFI",
 "Payments": "Yes",
 "Savings": "Yes",
 "Credit": "Yes",
 "Insurance": "Yes",
 "Non-financial": "Yes",
 "Bundled": null,
 "Climate lens": "Climate-supportive",
 "Climate risk addressed": null,
 "Climate risk addressed 2": null,
 "Climate risk time horizon": "Long-term",
 "Before": "Yes",
 "During": null,
 "After": null,
 "Target end users": "Provide microfinance services to individuals and small businesses without access to them",
 "Rural vs urban": "Both",
 "Gender lens": "Uintentional",
 "End user coverage": null,
 "Country focus": "Jordan",
 "Latin America": "No",
 "Sub-Saharan Africa": "No",
 "South Asia": "No",
 "South East Asia": "No",
 "Pacific": "No",
 "Other": "Yes",
 "Physical": "Operates more than 30 branches in Jordan.",
 "Digital": "Offers internet and mobile banking.",
 "Website": "http://www.nmb.com.jo/en/about",
 "Website Product": "http://www.nmb.com.jo/en/node/171",
 "Unnamed: 36": null,
 },
 {
 "Product type": "Energy loan",
 "Product name": "Energy Loan",
 "Product description": "Energy loan can be disbursed by any customer member of the financial institution on the recommendation of the group for construction of Gobar Gas, Solar, Sudhariyeko chulo, Panighatta, Solar Tuki and other energy purposes.",
 "Product typology": "Climate-specific asset financing",
 "FSP name": "Womimfi",
 "FSP type": "Microfinance Institution",
 "FSP type (short)": "MFI",
 "Partner type": "MFI",
 "Payments": "Yes",
 "Savings": "Yes",
 "Credit": "Yes",
 "Insurance": "No",
 "Non-financial": null,
 "Bundled": null,
 "Climate lens": "Climate-supportive",
 "Climate risk addressed": null,
 "Climate risk addressed 2": null,
 "Climate risk time horizon": "Long-term",
 "Before": "Yes",
 "During": null,
 "After": null,
 "Target end users": "Women",
 "Rural vs urban": "Rural",
 "Gender lens": "Intentional",
 "End user coverage": null,
 "Country focus": "Nepal",
 "Latin America": "No",
 "Sub-Saharan Africa": "No",
 "South Asia": "Yes",
 "South East Asia": "No",
 "Pacific": "No",
 "Other": "No",
 "Physical": "Operates more than 90 branches.",
 "Digital": null,
 "Website": "https://womimfi.com.np/",
 "Website Product": "https://womimfi.com.np/Womi_Loan_Products.php",
 "Unnamed: 36": null,
 },
 {
 "Product type": "Energy loan",
 "Product name": "Green Business Loan Program",
 "Product description": "Consumer eco and green loans towards reduction of air pollution and GREEN BUSINESS LOAN PROGRAM which supports green projects and activities to protect the environment. The Green Business Loan Program is offered in partnership with the Ministry of Environment and Tourism. The loan is offered for a limited time.",
 "Product typology": "Climate-specific input credit",
 "FSP name": "XacBank",
 "FSP type": "Bank",
 "FSP type (short)": "Bank",
 "Partner type": "Bank",
 "Payments": "Yes",
 "Savings": "Yes",
 "Credit": "Yes",
 "Insurance": "No",
 "Non-financial": null,
 "Bundled": null,
 "Climate lens": "Climate-supportive",
 "Climate risk addressed": null,
 "Climate risk addressed 2": null,
 "Climate risk time horizon": "Long-term",
 "Before": "Yes",
 "During": null,
 "After": null,
 "Target end users": "Consumers and businesses",
 "Rural vs urban": "Both",
 "Gender lens": "Uintentional",
 "End user coverage": null,
 "Country focus": "Mongolia",
 "Latin America": "No",
 "Sub-Saharan Africa": "No",
 "South Asia": "No",
 "South East Asia": "No",
 "Pacific": "No",
 "Other": "Yes",
 "Physical": "Operates an office.",
 "Digital": "Offers online and mobile banking.",
 "Website": "https://www.xacbank.mn/",
 "Website Product": "https://www.xacbank.mn/product/1141",
 "Unnamed: 36": null,
 },
];
