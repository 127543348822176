import React, { useState, useRef, useEffect, useMemo, useCallback} from 'react';
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import Select from 'react-select'

import 'ag-grid-community/dist/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/dist/styles/ag-theme-alpine.css'; // Optional theme CSS

import { dataAfrica } from '../databases/africa.js'
import { dataWorldMFSBasicSearch } from '../databases/world_mfis_basic_services.js'
import { dataGreenMfiEnergyWash } from '../databases/cgap.js'

import "./index.scss";

const dataSourceOptions = [
  { value: 'green_microfinance', label: 'Green Microfinance - Energy and WASH Loans Database' },
  { value: 'world_mfis_basic_services', label: 'World MFIs basic services' },
  
  //{ value: 'mfis_world', label: 'MFIs World' }
];


const App = () => {

const gridRef = useRef();
const [rowData, setRowData] = useState();

const [columnDefs, setColumnDefs] = useState();

 // DefaultColDef sets props common to all Columns
const defaultColDef = useMemo(() => ({sortable: true}));

 // Example of consuming Grid Event
 const cellClickedListener = useCallback( event => {
   console.log('cellClicked', event);
 }, []);

 // Example load data from sever
 useEffect(() => {
  //  fetch('https://www.ag-grid.com/example-assets/row-data.json')
  //  .then(result => result.json())
  //  .then(rowData => setRowData(rowData))
  setRowData(dataGreenMfiEnergyWash);
  setColumnDefs(Object.keys(dataGreenMfiEnergyWash[0]).map((keyName, idx) => {
    return {
      field: keyName, 
      //
      filter: true,
      cellRenderer: function(params) {
        if (String(params.value).includes('http')) {
          return <a href={params.value} target="_blank">{params.value}</a>;
        }
        return params.value;
      },
      resizable:true,
      wrapText: true,
      autoHeight: true
    };
  }));
 }, []);

const selectChangeListner = useCallback( selected => {
  // console.log("selectChangeListner", selected);
  if (selected.value === "world_mfis_basic_services") {
    setRowData(dataWorldMFSBasicSearch);
    setColumnDefs(Object.keys(dataWorldMFSBasicSearch[0]).map((keyName, idx) => {
      return {
        field: keyName, 
        filter: idx !== 0,
        cellRenderer: function(params) {
          if (String(params.value).includes('http')) {
            return <a href={params.value} target="_blank">{params.value}</a>;
          }
          
          return params.value;
        },
        resizable:true,
        wrapText: true,
        autoHeight: true
  
      };
    }));
  } else if (selected.value === "mfis_world") {
    setRowData(dataAfrica);
    setColumnDefs(Object.keys(dataAfrica[0]).map((keyName, idx) => {
      return {
        field: keyName, 
        filter: idx !== 0,
        cellRenderer: function(params) {
          if (String(params.value).includes('http')) {
            return <a href={params.value} target="_blank">{params.value}</a>;
          }
          
          return params.value;
        },
        resizable:true,
        wrapText: true,
        autoHeight: true
  
      };
    }));
  } else if (selected.value === "green_microfinance") {

    

    setRowData(dataGreenMfiEnergyWash);
    setColumnDefs(Object.keys(dataGreenMfiEnergyWash[0]).map((keyName, idx) => {
      return {
        field: keyName, 
        filter: true,
        cellRenderer: function(params) {
          if (String(params.value).includes('http')) {
            return <a href={params.value} target="_blank">{params.value}</a>;
          }
          
          return params.value;
        },
        resizable:true,
        wrapText: true,
        autoHeight: true
  
      };
    }));
  } 
}, []);

 return (
   <div>

    <Select
      options={dataSourceOptions}
      defaultValue={dataSourceOptions[0]}
      onChange={selectChangeListner}/>

    {rowData == null ? 
      <h1>Please select a source</h1>
      :
      
      <div className="ag-theme-alpine" style={{width: '100%', height: 700, fontFamily: "Montserrat"}}>
        

        <AgGridReact
            ref={gridRef} // Ref for accessing Grid's API
            rowData={rowData} // Row Data for Rows
            columnDefs={columnDefs} // Column Defs for Columns
            defaultColDef={defaultColDef} // Default Column Properties
            suppressMenuHide={true}
            animateRows={true} // Optional - set to 'true' to have rows animate when sorted
            rowSelection='multiple' // Options - allows click selection of rows
            onCellClicked={cellClickedListener} // Optional - registering for Grid Event
            />
      </div>
    }
   </div>
 );
};

export default App;